export enum HelpCenterLink {
    CancelTeamMemberOrder = 'https://help.hofy.com/hc/en-gb/articles/8062648619281',
    PurchaseTeamMemberOrder = 'https://hofy.zendesk.com/hc/en-gb/articles/8145163387537',
    ManagerPermissions = 'https://hofy.zendesk.com/hc/en-gb/articles/4534030520849',
    CountryMultipliers = 'https://hofy.zendesk.com/hc/en-gb/articles/9985184018193-Country-multiplier',
    HowInvoicingWorks = 'https://hofy.zendesk.com/hc/en-gb/articles/4534491777809-How-Hofy-invoicing-works',
    HowPaymentWorks = 'https://help.hofy.com/hc/en-gb/articles/21681937841809',
    OrganisationCreditLimit = 'https://hofy.zendesk.com/hc/en-gb/articles/6833831576209',
    SecureLogIn = 'https://hofy.zendesk.com/hc/en-gb/articles/11098387151889-Log-in-securely-on-Hofy',
    EquipmentCategoriesAndSubCategories = 'https://help.hofy.com/hc/en-gb/articles/5885496710673-Managing-teams-Allowance-Approvals',
    DseAssessment = 'https://help.hofy.com/hc/en-gb/articles/4529633123473-DSE-assessment',
    CountryIdentifiers = 'https://help.hofy.com/hc/en-gb/articles/7571892725393',
    UsersUpload = 'https://www.notion.so/hofy/User-Bulk-Upload-638cff8520044996bd29ddcce691fb51',
    Main = 'https://help.hofy.com/hc/en-gb',
    TermsAndConditions = 'https://www.hofy.com/terms-of-service',
    SerialNumberLocation = 'https://help.hofy.com/hc/en-gb/articles/8976991219473-How-to-locate-your-equipment-s-serial-number-or-service-tags',
}
