import React, { FC } from 'react';

import { ItemStatusUpdateDto } from '@hofy/api-admin';
import { formatDateTime } from '@hofy/helpers';
import { BaseTable, Paragraph3, Placeholder, SvgIllustration } from '@hofy/ui';

import { ItemStatusChip } from '../../components/domain/items/ItemStatusChip';
import { ActorLabel } from '../auditLogsPage/components/ActorLabel';

interface StatusUpdateTableProps {
    itemStatusUpdate: ItemStatusUpdateDto[];
}

export const StatusUpdateTable: FC<StatusUpdateTableProps> = ({ itemStatusUpdate }) => {
    return (
        <BaseTable
            data={itemStatusUpdate}
            toKey={status => status.idDeprecated}
            emptyContent={<Placeholder illustration={SvgIllustration.List} title='No status history' />}
            height='auto'
            headerHorizontalPadding={40}
            rowHorizontalPadding={30}
            columns={[
                {
                    id: 'status',
                    header: 'Status',
                    flexGrow: 1,
                    width: 180,
                    renderer: ({ status }) => <ItemStatusChip status={status} />,
                },
                {
                    id: 'changedOn',
                    header: 'Status changed at',
                    flexGrow: 1,
                    width: 180,
                    renderer: ({ statusUpdatedAt }) => formatDateTime(statusUpdatedAt),
                },
                {
                    id: 'changedBy',
                    header: 'Updated by',
                    flexGrow: 1,
                    width: 180,
                    renderer: ({ createdBy }) => (
                        <Paragraph3>
                            <ActorLabel actor={createdBy} />
                        </Paragraph3>
                    ),
                },
                {
                    id: 'explanation',
                    header: 'Explanation',
                    flexGrow: 1,
                    width: 180,
                    renderer: ({ statusExplanation }) => statusExplanation,
                },
            ]}
        />
    );
};
