import {
    HofyWarehouseDetailsDto,
    PurchaseOrderDetailsDto,
    PurchaseOrderPayload,
    ShipmentDetailsUserDto,
    SupplierDetailDto,
} from '@hofy/api-admin';
import { HofySubsidiaryRef } from '@hofy/api-shared';
import { Currency, DateString, Percent, percentToNumber, Price, UUID, zeroPrice } from '@hofy/global';
import { nowISODate } from '@hofy/helpers';

export interface PurchaseOrderFormItemData {
    variant: UUID | null;
    name?: string;
    supplierCode: string | null;
    quantity: number;
    unitNetPrice: Price;
    totalGrossPrice: Price;
    taxRate: Percent;
    warranty: number | null;
    estimatedReceiveOn: DateString | null;
    notes: string | null;
}

export interface PurchaseOrderFormData {
    shipmentId: number | null;
    supplierId: number | null;
    supplier: SupplierDetailDto | null;
    currency: Currency;
    isDropship: boolean;
    hofySubsidiaryId: number | null;
    hofySubsidiary: HofySubsidiaryRef | null;
    toWarehouse: HofyWarehouseDetailsDto | null;
    toWarehouseId: number | null;
    toUserId: number | null;
    toUser: Partial<ShipmentDetailsUserDto> | null;
    estimatedReceiptOn: DateString | null;
    orderedOn: DateString;
    totalNetValue: Price;
    totalHandlingCost: Price;
    totalValue: Price;
    notes: string | null;
    items: PurchaseOrderFormItemData[];
    purchaseOrderReference: string | null;
    purchaseOrderSupplierReference: string | null;
}

const defaultCurrency = Currency.USD;
export const emptyPurchaseOrderItemFormData = (currency?: Currency, estimatedReceiveOn?: DateString) => {
    return {
        variant: null,
        supplierCode: '',
        quantity: 1,
        unitNetPrice: zeroPrice(currency ?? defaultCurrency),
        totalGrossPrice: zeroPrice(currency ?? defaultCurrency),
        taxRate: '0.00',
        estimatedReceiveOn: estimatedReceiveOn ?? null,
        notes: null,
        warranty: null,
    };
};
export const emptyPurchaseOrderFormData: PurchaseOrderFormData = {
    shipmentId: null,
    supplierId: null,
    supplier: null,
    currency: defaultCurrency,
    isDropship: false,
    hofySubsidiaryId: null,
    hofySubsidiary: null,
    toWarehouse: null,
    toWarehouseId: null,
    toUserId: null,
    toUser: null,
    estimatedReceiptOn: null,
    orderedOn: nowISODate(),
    totalNetValue: zeroPrice(defaultCurrency),
    totalHandlingCost: zeroPrice(defaultCurrency),
    totalValue: zeroPrice(defaultCurrency),
    items: [emptyPurchaseOrderItemFormData()],
    notes: null,
    purchaseOrderReference: null,
    purchaseOrderSupplierReference: null,
};

export const createUpdatePurchaseOrderFormDataToPayload = (
    data: PurchaseOrderFormData,
): PurchaseOrderPayload => {
    return {
        isDropship: data.isDropship,
        shipmentId: data.shipmentId,
        supplierId: data.supplierId!,
        currency: data.currency,
        hofySubsidiaryId: data.hofySubsidiaryId!,
        orderedOn: data.orderedOn,
        estimatedReceiveOn: data.estimatedReceiptOn!,
        toUserId: data.toUserId,
        toWarehouseId: data.toWarehouseId,
        totalHandlingCost: data.totalHandlingCost,
        notes: data.notes ?? '',
        purchaseOrderReference: data.purchaseOrderReference ?? '',
        purchaseOrderSupplierReference: data.purchaseOrderSupplierReference ?? null,
        items: data.items.map(item => {
            return {
                variantId: item.variant!,
                quantity: item.quantity,
                unitNetPrice: item.unitNetPrice,
                taxRate: item.taxRate,
                supplierCode: item.supplierCode,
                estimatedReceiveOn: item.estimatedReceiveOn,
                notes: item.notes ?? '',
                warranty: item.warranty,
            };
        }),
    };
};

export const createUpdatePurchaseOrderDetailsDtoToFormData = (
    purchaseOrderDetailsDto: PurchaseOrderDetailsDto,
): Partial<PurchaseOrderFormData> => {
    const items = purchaseOrderDetailsDto.purchaseOrderItems.map(item => {
        const unitNetAmount = parseFloat(item.unitNetPrice.amount);
        const unitGrossAmount = unitNetAmount + (unitNetAmount * percentToNumber(item.taxRate)) / 100;
        return {
            variant: item.variantId,
            name: item.variant.product.name,
            quantity: item.quantity,
            unitNetPrice: item.unitNetPrice,
            taxRate: item.taxRate,
            totalGrossPrice: {
                amount: (item.quantity * unitGrossAmount).toString(),
                currency: item.unitNetPrice.currency,
            },
            supplierCode: item.supplierCode,
            estimatedReceiveOn: item.estimatedReceiveOn,
            notes: item.notes,
            warranty: item.warranty,
        } as PurchaseOrderFormItemData;
    });

    return {
        currency: purchaseOrderDetailsDto.currency,
        estimatedReceiptOn: purchaseOrderDetailsDto.estimatedReceiveOn,
        hofySubsidiary: purchaseOrderDetailsDto.hofySubsidiary,
        hofySubsidiaryId: purchaseOrderDetailsDto.hofySubsidiaryId,
        isDropship: purchaseOrderDetailsDto.isDropship,
        notes: purchaseOrderDetailsDto.notes,
        orderedOn: purchaseOrderDetailsDto.orderedOn,
        shipmentId: purchaseOrderDetailsDto.purchaseOrderShipments?.[0]?.id,
        supplierId: purchaseOrderDetailsDto.supplier.id,
        supplier: null,
        toUser: null,
        toUserId: purchaseOrderDetailsDto.toUser?.id ?? null,
        toWarehouse: null,
        toWarehouseId: purchaseOrderDetailsDto.toWarehouse?.id ?? null,
        totalHandlingCost: purchaseOrderDetailsDto.handlingCost,
        totalValue: purchaseOrderDetailsDto.totalPrice,
        purchaseOrderReference: purchaseOrderDetailsDto.purchaseOrderReference,
        purchaseOrderSupplierReference: purchaseOrderDetailsDto.purchaseOrderSupplierReference,
        items,
    };
};
