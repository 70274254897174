import { DateString } from '@hofy/global';

import { PublicRefDto } from './PublicRefDto';

export interface UserRefDto extends PublicRefDto {
    idDeprecated: number;
    firstName: string;
    lastName: string;
    isOffBoarded: boolean | null;
    pictureUrl: string | null;
    newJoinerStartsOn: DateString | null;
}

export const formatAdminName = (v: UserRefDto) =>
    `${v.firstName} ${v.lastName}${v.isOffBoarded ? ' (Offboarded)' : ''}`;
export const unassignedUser = 0;
