import { omit } from 'lodash';

import { AdminPurchaseOrdersFilter } from '@hofy/api-admin';
import { NetsuiteSyncStatus, PurchaseOrderStatus, unassignedUser } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { countObjectValues } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import { useArrayQueryParam, useBooleanQueryParam, useStringQueryParam } from '@hofy/router';

export const useAdminPurchaseOrderFilters = (defaultSuppliers: number[]) => {
    const [search, setSearch] = useStringQueryParam('search', '');

    const [purchaseOrderStatuses, setPurchaseOrderStatuses] = useArrayQueryParam<PurchaseOrderStatus>(
        'status',
        [],
    );
    const [createdByUsers, setCreatedByUsers] = useArrayQueryParam<number>('createdByUsers', []);
    const [suppliers, setSuppliers] = useArrayQueryParam<number>('suppliers', defaultSuppliers);
    const [warehouses, setWarehouses] = useArrayQueryParam<number>('warehouses', []);
    const [isDropship, toggleIsDropship] = useBooleanQueryParam('isDropship', false);
    const [variants, setVariants] = useArrayQueryParam<UUID>('variants', []);
    const [netsuiteStatuses, setNetsuiteStatuses] = useArrayQueryParam<NetsuiteSyncStatus>(
        'netsuiteStatus',
        [],
    );

    const setUnassigned = () => {
        setCreatedByUsers([unassignedUser]);
    };

    const filters = useStructMemo<AdminPurchaseOrdersFilter>({
        search,
        purchaseOrderStatuses,
        createdByUsers,
        suppliers,
        warehouses,
        isDropship,
        variants,
        netsuiteStatuses,
    });

    const filtersToCount = omit(filters, 'search', 'isDropship');
    const filterCount = countObjectValues(filtersToCount);

    return {
        filters,
        filterCount,
        setSearch,
        setPurchaseOrderStatuses,
        setCreatedByUsers,
        setSuppliers,
        setWarehouses,
        setVariants,
        toggleIsDropship,
        setUnassigned,
        setNetsuiteStatuses,
    };
};
