import React, { FC } from 'react';

import {
    AuditableTable,
    AvailableTokenCount,
    ConsumedTokenDto,
    OrganizationAddonConfigDetailsDto,
    useOrganizationAddonConfigQuery,
} from '@hofy/api-admin';
import {
    allPaymentSchemasWithoutDeposit,
    Permission,
    useTrAddonItem,
    useTrPaymentSchema,
} from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { MoreMenu, Slideout, SlideoutContent, SlideoutFooter, SlideoutTabbedHeader } from '@hofy/common';
import { decimalToNumber, UUID } from '@hofy/global';
import { formatDateTime } from '@hofy/helpers';
import {
    Alert,
    BaseTable,
    Box,
    ErrorStatePlaceholder,
    Form,
    FormNumberInput,
    FormSelect,
    FormSwitch,
    Heading3,
    PageSkeleton,
    Paragraph3,
    Placeholder,
    SubmitButton,
    SvgIllustration,
    Tab,
    Tabs,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useAuditLogMenuOption } from '../../../store/auditLogs/useAuditMenuOption';
import { useUpdateOrganizationAddonConfig } from '../../../store/organizationAddonConfigs/useUpdateOrganizationAddonConfig';
import {
    addonConfigurationSlideoutLabels,
    AddonConfigurationSlideoutTab,
    allAddonConfigurationSlideoutTabs,
} from './types/AddonConfigurationSlideoutTab';

interface UpdateAddonConfigurationSlideoutProps {
    addonId: number;
    organizationId: UUID;
    onClose(): void;
}

export const UpdateAddonConfigurationSlideout: FC<UpdateAddonConfigurationSlideoutProps> = ({
    addonId,
    organizationId,
    onClose,
}) => {
    const {
        data: addonConfig,
        isLoading,
        isError,
    } = useOrganizationAddonConfigQuery(organizationId, addonId);

    const [activeTab, setActiveTab] = React.useState(AddonConfigurationSlideoutTab.Configuration);

    return (
        <Slideout width={600} onClose={onClose}>
            <SlideoutTabbedHeader
                tabsSlot={
                    <Tabs active={activeTab} onChange={t => setActiveTab(t)}>
                        {allAddonConfigurationSlideoutTabs.map(tab => (
                            <Tab key={tab} id={tab} label={addonConfigurationSlideoutLabels[tab]} />
                        ))}
                    </Tabs>
                }
                title={
                    <Box row gap={20}>
                        <Heading3>{addonConfig?.addonName ?? 'Loading addon...'}</Heading3>
                        {addonConfig?.id && (
                            <UpdateAddonConfigurationSlideoutMoreMenu configId={addonConfig.id} />
                        )}
                    </Box>
                }
            />
            {(() => {
                if (isLoading || !addonConfig) {
                    return <PageSkeleton />;
                }
                if (isError) {
                    return <ErrorStatePlaceholder />;
                }

                switch (activeTab) {
                    case AddonConfigurationSlideoutTab.Configuration: {
                        return (
                            <UpdateAddonConfigurationSlideoutContent
                                addonId={addonId}
                                addonConfiguration={addonConfig}
                                organizationId={organizationId}
                                onClose={onClose}
                            />
                        );
                    }
                    case AddonConfigurationSlideoutTab.TokenPoolUsage: {
                        const tokensByPool = addonConfig.tokensByPool;
                        if (!tokensByPool) {
                            return <Placeholder title='No token pools' illustration={SvgIllustration.List} />;
                        }

                        return <PoolTokenTable tokensByPool={tokensByPool} />;
                    }
                    case AddonConfigurationSlideoutTab.ConsumedTokens: {
                        const consumedTokens = addonConfig.consumedTokens;
                        if (!consumedTokens.length) {
                            return (
                                <Placeholder title='No consumed tokens' illustration={SvgIllustration.List} />
                            );
                        }

                        return <ConsumedTokensTable consumedTokens={consumedTokens} />;
                    }
                    default:
                        activeTab satisfies never;
                }
            })()}
        </Slideout>
    );
};

interface UpdateAddonConfigurationSlideoutContentProps {
    addonId: number;
    addonConfiguration: OrganizationAddonConfigDetailsDto;
    organizationId: UUID;
    onClose(): void;
}

const UpdateAddonConfigurationSlideoutContent: FC<UpdateAddonConfigurationSlideoutContentProps> = ({
    addonId,
    addonConfiguration,
    organizationId,
    onClose,
}) => {
    const trPaymentSchema = useTrPaymentSchema();
    const { form, isPending, isError } = useUpdateOrganizationAddonConfig(
        organizationId,
        addonId,
        addonConfiguration,
        onClose,
    );
    const { addonName, active } = addonConfiguration;
    const { hasPermission } = useSession();
    const canUpdate = hasPermission(Permission.AdminAddonDiscountsUpdate);
    return (
        <Form
            onSubmit={form.submit}
            isLoading={isPending}
            isError={isError}
            disabled={!canUpdate}
            column
            flex='auto'
        >
            <SlideoutContent paddingVertical={40} column gap={40} overflow='auto' flex='auto'>
                <Box column gap={24}>
                    <Box column gap={16}>
                        <FormSwitch
                            label='Active'
                            api={form.fields.active}
                            disabled={active.state && active.isReadonly}
                        />
                        {active.state && active.isReadonly && (
                            <Alert
                                type='informative'
                                description={`${addonName} is a public addon, so is always active`}
                            />
                        )}
                    </Box>
                    <FormNumberInput
                        label='Discount'
                        api={form.fields.discount}
                        rightSlot={<Paragraph3>%</Paragraph3>}
                    />
                    <FormNumberInput label='Max asset age (months)' api={form.fields.assetAgeMax} nullable />
                    <FormNumberInput
                        label='Default enrollment duration (months)'
                        api={form.fields.enrollmentDuration}
                        nullable
                    />
                    <FormSelect
                        label='Default enrollment payment schema'
                        api={form.fields.enrollmentPaymentSchema}
                        options={allPaymentSchemasWithoutDeposit}
                        toText={trPaymentSchema}
                        nullable
                    />
                </Box>
            </SlideoutContent>
            <SlideoutFooter>
                <CancelButton label='Cancel' onClick={onClose} />
                <SubmitButton label='Save' />
            </SlideoutFooter>
        </Form>
    );
};

interface UpdateAddonConfigurationSlideoutHeaderProps {
    configId: number;
}

const UpdateAddonConfigurationSlideoutMoreMenu: FC<UpdateAddonConfigurationSlideoutHeaderProps> = ({
    configId,
}) => {
    const [auditMenuItem] = useAuditLogMenuOption(AuditableTable.AddonDiscounts, configId);
    const menuItems = [auditMenuItem];
    return <MoreMenu items={menuItems} />;
};

interface PoolTokenTableProps {
    tokensByPool: Record<string, AvailableTokenCount>;
}

const PoolTokenTable: FC<PoolTokenTableProps> = ({ tokensByPool }) => {
    return (
        <BaseTable
            data={Object.entries(tokensByPool).map(([pool, tokenCounts]) => ({
                pool,
                ...tokenCounts,
            }))}
            toKey={({ pool }) => pool}
            columns={[
                {
                    id: 'pool',
                    header: 'Pool',
                    flexGrow: 1,
                    renderer: ({ pool }) => pool,
                },
                {
                    id: 'total',
                    header: 'Total',
                    flexGrow: 0,
                    renderer: ({ total }) => total,
                },
                {
                    id: 'used',
                    header: 'Used',
                    flexGrow: 0,
                    renderer: ({ used }) => used,
                },
                {
                    id: 'available',
                    header: 'Available',
                    flexGrow: 0,
                    renderer: ({ available }) => available,
                },
            ]}
        />
    );
};

interface ConsumedTokensTableProps {
    consumedTokens: ConsumedTokenDto[];
}

const ConsumedTokensTable: FC<ConsumedTokensTableProps> = ({ consumedTokens }) => {
    const trAddonItem = useTrAddonItem();
    return (
        <BaseTable
            data={consumedTokens}
            toKey={(_, index) => index}
            columns={[
                {
                    id: 'service',
                    header: 'Service',
                    flexGrow: 1,
                    renderer: ({ service }) => trAddonItem(service),
                },
                {
                    id: 'total',
                    header: 'Multiplier',
                    flexGrow: 1,
                    renderer: ({ multiplier }) => decimalToNumber(multiplier),
                },
                {
                    id: 'consumed at',
                    header: 'Consumed at',
                    flexGrow: 1,
                    renderer: ({ consumedAt }) => formatDateTime(consumedAt),
                },
            ]}
        />
    );
};
