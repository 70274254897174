import React, { FC } from 'react';

import {
    isAdminOrganizationWarehouse,
    OrganizationWarehouseDetailsDto,
    ShipmentDetailsDto,
    ShipmentDetailsUserDto,
} from '@hofy/api-admin';
import {
    formatUserName,
    isShipmentFromHofyWarehouse,
    isShipmentFromOrganizationWarehouse,
    isShipmentFromSupplierToUser,
    isShipmentFromUser,
    isShipmentFromUserToUser,
    isShipmentFromUserToWarehouse,
    isShipmentFromWarehouseToUser,
    isShipmentToHofyWarehouse,
    isShipmentToOrganizationWarehouse,
    isShipmentToUser,
} from '@hofy/api-shared';
import { Box, FormGridRow, FormSection, LabeledText } from '@hofy/ui';

import { AddressDetails } from '../../../../components/domain/address/AddressDetails';
import { AuditSection } from '../../../../components/domain/auditingInline/AuditSection';
import { UserDetailsLink } from '../../../usersPage/UserDetailsLink';
import { CourierDetails } from '../../components/CourierDetails';
import { ShipmentDetails } from './ShipmentDetails';
import { ShipmentStatusHistory } from './ShipmentStatusHistory';

interface ShipmentSlideoutDetailsProps {
    shipment: ShipmentDetailsDto;
}

export const ShipmentSlideoutDetails: FC<ShipmentSlideoutDetailsProps> = ({ shipment }) => {
    const { trackingLink, courier, courierReference, estimateOn, shipmentClass, scheduledOn } = shipment;

    return (
        <Box marginVertical={30} gap={30} column>
            <FormSection label='Shipment details' marginRight={40} flex={1} overflow='hidden'>
                <FormGridRow columns={2}>
                    <LabeledText label='Uuid' content={shipment.uuid} />
                </FormGridRow>
            </FormSection>

            <Box row alignItems='flex-start'>
                <FormSection label='Courier details' marginRight={40} flex={1} overflow='hidden'>
                    <CourierDetails
                        courierReference={courierReference}
                        courier={courier}
                        trackingLink={trackingLink}
                        shipmentClass={shipmentClass}
                    />
                </FormSection>
                {(isShipmentFromWarehouseToUser(shipment) || isShipmentFromSupplierToUser(shipment)) && (
                    <FormSection label='Delivery details' flex={1} overflow='hidden'>
                        <ShipmentDetails
                            publicId={shipment.publicId}
                            label='Estimated arrival date'
                            estimateOn={estimateOn}
                            warehouse={
                                isShipmentFromWarehouseToUser(shipment) ? shipment.fromWarehouse : undefined
                            }
                            latestWithCourier={shipment.latestWithCourierOn}
                            numberOfBoxes={shipment.numberOfBoxes ?? 1}
                        />
                    </FormSection>
                )}
                {isShipmentFromUserToUser(shipment) && (
                    <FormSection label='Transfer details' flex={1} overflow='hidden'>
                        {scheduledOn && (
                            <ShipmentDetails
                                label='Estimated transfer collection date'
                                publicId={shipment.publicId}
                                scheduledOn={scheduledOn}
                                latestWithCourier={shipment.latestWithCourierOn}
                                numberOfBoxes={shipment.numberOfBoxes ?? 1}
                            />
                        )}
                        <ShipmentDetails
                            label='Estimated transfer delivery date'
                            publicId={shipment.publicId}
                            estimateOn={estimateOn}
                            latestWithCourier={shipment.latestWithCourierOn}
                            numberOfBoxes={shipment.numberOfBoxes ?? 1}
                        />
                    </FormSection>
                )}
                {isShipmentFromUserToWarehouse(shipment) && (
                    <FormSection label='Collection details' flex={1} overflow='hidden'>
                        <ShipmentDetails
                            label='Estimated collection date'
                            publicId={shipment.publicId}
                            estimateOn={estimateOn}
                            warehouse={shipment.toWarehouse}
                            latestWithCourier={shipment.latestWithCourierOn}
                            numberOfBoxes={shipment.numberOfBoxes ?? 1}
                        />
                    </FormSection>
                )}
            </Box>

            {/* Origin details */}
            {isShipmentFromUser(shipment) && shipment.fromUser && (
                <FormSection label='From user'>
                    <UserBlock user={shipment.fromUser} />
                </FormSection>
            )}
            {isShipmentFromHofyWarehouse(shipment) && (
                <FormSection label='From Hofy warehouse'>
                    <LabeledText flex={1} label='Name' content={shipment.fromWarehouse.name} />
                </FormSection>
            )}
            {isShipmentFromOrganizationWarehouse(shipment) &&
                isAdminOrganizationWarehouse(shipment.fromWarehouse) && (
                    <FormSection label='From organization storage location'>
                        <OrganizationWarehouseBlock warehouse={shipment.fromWarehouse} />
                    </FormSection>
                )}
            <FormSection label='From address'>
                <AddressDetails address={shipment.fromAddress} />
            </FormSection>

            {/* Destination details */}
            {isShipmentToUser(shipment) && shipment.toUser && (
                <FormSection label='To user'>
                    <UserBlock user={shipment.toUser} />
                </FormSection>
            )}
            {isShipmentToHofyWarehouse(shipment) && (
                <FormSection label='To Hofy warehouse'>
                    <LabeledText flex={1} label='Name' content={shipment.toWarehouse.name} />
                </FormSection>
            )}
            {isShipmentToOrganizationWarehouse(shipment) &&
                isAdminOrganizationWarehouse(shipment.toWarehouse) && (
                    <FormSection label='To organization storage location'>
                        <OrganizationWarehouseBlock warehouse={shipment.toWarehouse} />
                    </FormSection>
                )}
            <FormSection label='To address'>
                <AddressDetails address={shipment.toAddress} />
            </FormSection>

            <FormSection label='Status history'>
                <ShipmentStatusHistory shipment={shipment} />
            </FormSection>
            <AuditSection model={shipment} />
        </Box>
    );
};

interface UserBlockProps {
    user: ShipmentDetailsUserDto;
}

const UserBlock: FC<UserBlockProps> = ({ user }) => {
    return (
        <>
            <Box row>
                <LabeledText
                    flex={1}
                    label='Id'
                    content={<UserDetailsLink id={user.id}>#{user.id}</UserDetailsLink>}
                />
                <LabeledText flex={1} label='Name' content={formatUserName(user)} />
            </Box>
            <Box row>
                <LabeledText flex={1} label='Work email' content={user.workEmail} />
                <LabeledText flex={1} label='Personal email' content={user.personalEmail} />
            </Box>
        </>
    );
};

interface OrganizationWarehouseBlockProps {
    warehouse: OrganizationWarehouseDetailsDto;
}

const OrganizationWarehouseBlock: FC<OrganizationWarehouseBlockProps> = ({ warehouse }) => {
    const primaryManager = warehouse.organizationWarehouse.primaryManager;
    const secondaryManager = warehouse.organizationWarehouse.secondaryManager;

    return (
        <>
            <LabeledText label='Location name' content={warehouse.name} />
            <LabeledText
                label='Storage primary manager'
                content={
                    primaryManager?.id && (
                        <UserDetailsLink id={primaryManager.idDeprecated}>
                            {formatUserName(primaryManager)}
                        </UserDetailsLink>
                    )
                }
            />
            <LabeledText
                label='Storage secondary manager'
                content={
                    secondaryManager?.id && (
                        <UserDetailsLink id={secondaryManager.idDeprecated}>
                            {formatUserName(secondaryManager)}
                        </UserDetailsLink>
                    )
                }
            />
        </>
    );
};
