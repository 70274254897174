import React, { FC } from 'react';

import { FixedAssetDto } from '@hofy/api-admin';
import { RangeBar, RangeBarBackground } from '@hofy/common';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { Color } from '@hofy/theme';
import {
    BaseTable,
    Box,
    Paragraph3,
    Paragraph4,
    Placeholder,
    SvgIllustration,
    TextCell,
    TwoLineTextCell,
} from '@hofy/ui';

import { FixedAssetStatusChip } from '../../components/domain/fixedAssets/FixedAssetStatusChip';

interface FixedAssetsTableProps {
    fixedAssets: FixedAssetDto[];
    maxWidth?: number;
}

export const FixedAssetsTable: FC<FixedAssetsTableProps> = ({ fixedAssets, maxWidth = 1000 }) => {
    const { formatPriceWithISOCode: formatPrice } = usePrice();
    return (
        <BaseTable
            data={fixedAssets}
            toKey={fixedAsset => fixedAsset.id}
            emptyContent={<Placeholder illustration={SvgIllustration.AssetsSearch} title='No fixed assets' />}
            headerHorizontalPadding={40}
            rowHorizontalPadding={30}
            maxWidth={maxWidth}
            height='auto'
            columns={[
                {
                    id: 'status',
                    header: 'Status',
                    flexGrow: 1,
                    width: 90,
                    renderer: fixedAsset => <FixedAssetStatusChip status={fixedAsset.status} />,
                },
                {
                    id: 'valuation',
                    header: 'Valuation',
                    flexGrow: 1,
                    width: 90,
                    renderer: fixedAsset => (
                        <TwoLineTextCell
                            line1={`Current: ${formatPrice(fixedAsset.currentValue)}`}
                            line2={`At activation: ${formatPrice(fixedAsset.valueAtActivation)}`}
                        />
                    ),
                },
                {
                    id: 'disposed_at',
                    header: 'Disposal',
                    flexGrow: 1,
                    width: 90,
                    renderer: fixedAsset => <TextCell>{formatDate(fixedAsset.disposedAt)}</TextCell>,
                },
                {
                    id: 'depreciation_dates',
                    header: 'Depreciation',
                    flexGrow: 1,
                    width: 90,
                    renderer: fixedAsset => (
                        <TwoLineTextCell
                            line1={`Last: ${formatDate(fixedAsset.lastDepreciatedOn)}`}
                            line2={`From: ${formatDate(fixedAsset.depreciationStart)}`}
                        />
                    ),
                },
                {
                    id: 'depreciation_progress',
                    header: 'Progress',
                    flexGrow: 1,
                    width: 90,
                    renderer: fixedAsset => <DepreciationCell fixedAsset={fixedAsset} />,
                },
            ]}
        />
    );
};

interface DepreciationCellProps {
    fixedAsset: FixedAssetDto;
}

const DepreciationCell: FC<DepreciationCellProps> = ({ fixedAsset }) => {
    const calculatePercentage = (fixedAsset: FixedAssetDto): string => {
        const progress = fixedAsset.assetLifetimeMonths - fixedAsset.remainingLifetimeMonths;
        const percentage = (progress / fixedAsset.assetLifetimeMonths) * 100;
        return `${Number(percentage.toFixed(0))}%`;
    };

    const label = () => {
        return `${fixedAsset.remainingLifetimeMonths} months remaining`;
    };
    return (
        <Box column fullWidth>
            <Paragraph3 color={Color.Neutral700} bold>
                {calculatePercentage(fixedAsset)}
            </Paragraph3>
            <RangeBarBackground row fullWidth marginTop={4} bg={Color.Neutral200}>
                <RangeBar
                    height={5}
                    fullWidth
                    minWidth={5}
                    width={calculatePercentage(fixedAsset)}
                    bg={Color.AccentGreen}
                />
            </RangeBarBackground>
            <Paragraph4 color={Color.Neutral700} marginTop={2}>
                {label()}
            </Paragraph4>
        </Box>
    );
};
