import React, { FC, useState } from 'react';

import { useShipmentsQuery } from '@hofy/api-admin';
import { pendingShipmentStatuses, ShipmentStatus, ShipmentType } from '@hofy/api-shared';
import { ComponentLoader, FormDropdown } from '@hofy/common';
import { Color } from '@hofy/theme';
import { Box, ConfirmModal, Icon, Paragraph3, SvgIcon } from '@hofy/ui';

import { useAppendAssignmentsToShipment } from '../../../store/shipments/useAppendAssignmentsToShipment';

interface AppendAssignmentToShipmentModalProps {
    assignmentId: number;
    currentShipmentId: number | null;
    toUserId: number | null;
    fromUserId: number | null;
    onSuccess(): void;
}

export const AppendAssignmentToShipmentModal: FC<AppendAssignmentToShipmentModalProps> = ({
    assignmentId,
    currentShipmentId,
    toUserId,
    fromUserId,
    onSuccess,
}) => {
    const [shipmentId, setShipmentId] = useState<number | null>(null);
    const { appendAssignmentToShipment } = useAppendAssignmentsToShipment(shipmentId);

    return (
        <ConfirmModal
            keyPrefix='append-assignment-to-shipment-modal'
            onClose={onSuccess}
            onConfirm={() => appendAssignmentToShipment([assignmentId])}
        >
            <ShipmentModalContent
                currentShipmentId={currentShipmentId}
                toUserId={toUserId}
                fromUserId={fromUserId}
                shipmentId={shipmentId}
                setShipmentId={setShipmentId}
            />
        </ConfirmModal>
    );
};

interface ShipmentModalContentProps {
    currentShipmentId: number | null;
    toUserId: number | null;
    fromUserId: number | null;
    shipmentId: number | null;
    setShipmentId(id: number | null): void;
}

const ShipmentModalContent: FC<ShipmentModalContentProps> = ({
    currentShipmentId,
    toUserId,
    fromUserId,
    shipmentId,
    setShipmentId,
}) => {
    const { shipments, shipmentsIsLoading: isLoading } = useShipmentsQuery({
        shipmentStatus: pendingShipmentStatuses.filter(t => t !== ShipmentStatus.WithCourier),
        shipmentTypes: getShipmentType(toUserId, fromUserId),
        assignedUser: [],
        toUserId: toUserId,
        fromUserId: fromUserId,
        shipToday: null,
        shouldUpdate: null,
        country: [],
        region: [],
        shipmentTrackingProviders: null,
        withTrackingException: null,
        withoutTracking: null,
        search: '',
    });

    if (isLoading) {
        return <ComponentLoader />;
    }
    const filteredShipments = shipments.filter(s => s.idDeprecated !== currentShipmentId);

    return (
        <>
            <FormDropdown
                items={filteredShipments}
                label='Append to shipment'
                labelFormatter={s => `Shipment #${s?.idDeprecated}`}
                value={shipments.find(s => s.idDeprecated === shipmentId)}
                onChange={shipment => setShipmentId(shipment?.idDeprecated || null)}
                isRequired
                disabled={filteredShipments.length === 0}
                marginTop={20}
                marginBottom={10}
            />
            {filteredShipments.length === 0 && (
                <Box row>
                    <Icon
                        svg={SvgIcon.AlertTriangle}
                        size={20}
                        marginRight={12}
                        color={Color.FoundationWarning}
                    />
                    <Paragraph3 color={Color.FoundationWarning} marginTop={2}>
                        No shipments available
                    </Paragraph3>
                </Box>
            )}
        </>
    );
};

const getShipmentType = (toUserId: number | null, fromUserId: number | null) => {
    if (toUserId !== null && fromUserId !== null) {
        return [ShipmentType.Transfer];
    } else if (toUserId !== null) {
        return [ShipmentType.Delivery, ShipmentType.Dropshipping];
    } else if (fromUserId !== null) {
        return [ShipmentType.Delivery, ShipmentType.Collection];
    }
    return [];
};
