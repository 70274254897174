import React, { FC, ReactNode } from 'react';

import { Color, VisualVariant } from '@hofy/theme';

import { renderTextNode } from '../../../helpers';
import { Box, BoxProps, Paragraph2, Paragraph3 } from '../../base';
import { StyledFeaturedIcon } from './ActionPressable';

interface ActionTileContentProps extends BoxProps {
    icon?: Svg;
    title: string;
    description?: ReactNode;
    disabled?: boolean;
    variant?: VisualVariant;
}

export const ActionTileContent: FC<ActionTileContentProps> = ({
    icon,
    title,
    description,
    disabled,
    variant = 'normal',
    ...rest
}) => {
    return (
        <Box row gap={16} alignItems={description ? 'flex-start' : 'center'} justify='flex-start' {...rest}>
            {icon && (
                <StyledFeaturedIcon
                    icon={icon}
                    shape='square'
                    size={40}
                    type={disabled ? 'neutral' : 'informative'}
                    variant={variant}
                    marginTop={4}
                />
            )}
            <Box column gap={4} flex={1}>
                <Paragraph2 bold color={Color.ContentPrimary}>
                    {title}
                </Paragraph2>
                {description ? renderTextNode(description, text => <Paragraph3>{text}</Paragraph3>) : null}
            </Box>
        </Box>
    );
};
