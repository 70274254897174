import { getEnumValues } from '@hofy/global';

export enum Role {
    TeamMember = 'team_member',

    Manager = 'manager',
    AccessManager = 'access_manager',
    FinancialManager = 'financial_manager',
    PiiManager = 'pii_manager',
    ReadOnlyManager = 'read_only_manager',

    OrganizationTeleporterAdmin = 'organization_teleporter_admin',
    ReadOnlyOrganizationTeleporterAdmin = 'read_only_organization_teleporter_admin',
    SuperAdmin = 'super_admin',
    ReadOnlyAdmin = 'read_only_admin',
    PermissionAdmin = 'permission_admin',
    UserAdmin = 'user_admin',
    FinanceDirectorAdmin = 'finance_director_admin',
    FinancialAdmin = 'financial_admin',
    FulfillmentAdmin = 'fulfillment_admin',
    ProcurementAdmin = 'procurement_admin',
    ProcurementApproveAdmin = 'procurement_approve_admin',
    FinancialApproveAdmin = 'financial_approve_admin',
    CatalogueAdmin = 'catalogue_admin',
    OrganizationAdmin = 'organization_admin',
    SalesAdmin = 'sales_admin',
    SupportAdmin = 'support_admin',
    SupportPlusAdmin = 'support_plus_admin',
    SuccessAdmin = 'success_admin',
    ITAdmin = 'it_admin',
    EarlyTesterAdmin = 'early_tester_admin',
}

export const allRoles = getEnumValues<Role>(Role);
export const allManagerRoles = [
    Role.Manager,
    Role.PiiManager,
    Role.AccessManager,
    Role.FinancialManager,
    Role.ReadOnlyManager,
];
export const allStorefrontRoles = [Role.TeamMember, ...allManagerRoles];
export const allAdminRoles = allRoles.filter(role => !allStorefrontRoles.includes(role));

export const hasAnyManagerRole = (roles: Role[]): boolean => {
    return roles.some(role => allManagerRoles.includes(role));
};
