import { Role } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

export interface SendOneOffPayload {
    message: string;
    title: string;
    roles: Role[];
    organizationId: UUID | null;
    userId: number | null;
}

export const emptySendOneOffPayload: SendOneOffPayload = {
    message: '',
    title: '',
    roles: [Role.Manager],
    organizationId: null,
    userId: null,
};
