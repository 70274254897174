import { restClient } from '@hofy/rest';

import { CreateExchangeRatePayload } from './types/CreateExchangeRatePayload';
import { ExchangeRateDto } from './types/ExchangeRateDto';

class ExchangeRatesService {
    public getExchangeRates = async (): Promise<ExchangeRateDto[]> => {
        const response = await restClient.getJson<{ exchangeRates: ExchangeRateDto[] }>(
            '/api/admin/exchange-rate',
        );
        return response.exchangeRates;
    };

    public deleteExchangeRate = async (id: number) => {
        return restClient.delete(`/api/admin/exchange-rate/${id}`);
    };

    public createExchangeRate = async (data: CreateExchangeRatePayload): Promise<void> => {
        await restClient.post('/api/admin/exchange-rate', data);
    };
}

export const exchangeRatesService = new ExchangeRatesService();
