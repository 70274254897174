import { AssignmentStatus } from '@hofy/api-shared';

export const assignmentConfigurationCacheKey = 'assignment/configuration';
// key reused in whole orders page
export const assignmentsCacheKey = 'assignment';
export const assignmentCacheKey = (id: number) => [assignmentsCacheKey, 'order', id];
export const userAssignmentCacheKey = (id: number, status?: AssignmentStatus[]) => [
    assignmentsCacheKey,
    'user',
    id,
    status,
];
