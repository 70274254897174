import { omit, xor } from 'lodash';

import { RepairStatus } from '@hofy/api-shared';
import { countObjectValues } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import { useArrayQueryParam, useBooleanQueryParam, useStringQueryParam } from '@hofy/router';

import { RepairsPageTab } from './repairsPageTab';

export const useListRepairsFilters = (tab: RepairsPageTab) => {
    const [search, setSearch] = useStringQueryParam('search', '');

    const [assignedUsers, setAssignedUsers] = useArrayQueryParam<number>('assignedUser');
    const toggleAssignedUser = (id: number) => {
        setAssignedUsers(xor(assignedUsers, [id]));
    };

    const [repairStatuses, setRepairStatuses] = useArrayQueryParam<RepairStatus>('repairStatus');
    const toggleRepairStatus = (status: RepairStatus) => {
        setRepairStatuses(xor(repairStatuses, [status]));
    };

    const [warehouses, setWarehouses] = useArrayQueryParam<number>('warehouse');
    const toggleWarehouse = (status: number) => {
        setWarehouses(xor(warehouses, [status]));
    };

    const [hasUnassignedUser, setHasUnassignedUser] = useBooleanQueryParam('hasUnassignedUser');
    const toggleHasUnassignedUser = () => setHasUnassignedUser(hasUnassignedUser ? null : true);

    const values = useStructMemo({
        isResolved: tab === RepairsPageTab.Resolved,
        search,
        assignedUsers,
        repairStatuses,
        warehouses,
        hasUnassignedUser,
    });

    const controls = {
        setSearch,
        setAssignedUsers,
        toggleAssignedUser,
        setRepairStatuses,
        toggleRepairStatus,
        setWarehouses,
        toggleWarehouse,
        toggleHasUnassignedUser,
    };

    const count = countObjectValues(omit(values, 'isResolved', 'search'));

    return {
        values,
        controls,
        count,
    };
};
