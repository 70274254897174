import React, { FC, ReactNode, useState } from 'react';

import {
    AssignmentWithProductDto,
    isAdminHofyWarehouse,
    itemsService,
    ShipmentDetailsDto,
} from '@hofy/api-admin';
import {
    formatVariant,
    isShipmentFromHofyWarehouse,
    isShipmentFromHofyWarehouseToUser,
    ShipmentStatus,
} from '@hofy/api-shared';
import { FormInput } from '@hofy/common';
import { Color } from '@hofy/theme';
import { Box, Button, FormSection, LabeledSwitch, Paragraph3, Span } from '@hofy/ui';

import { ProductItem } from '../../../../components/domain/products/ProductItem';
import { AssignmentDetailsLink } from '../../../assignmentsPage/AssignmentDetailsLink';
import { ItemDetails } from '../../../itemsPage/ItemDetails';
import { useFastCodeScan } from '../../hooks/useFastCodeScan';
import { ScanItemSlideout } from '../../scanItemSlideout/ScanItemSlideout';

interface ShipmentSlideoutOrdersProps {
    shipment: ShipmentDetailsDto;
    canChangeShipment: boolean;
}

export const ShipmentSlideoutAssignments: FC<ShipmentSlideoutOrdersProps> = ({
    shipment,
    canChangeShipment,
}) => {
    const [scanItem, setScanItem] = useState<AssignmentWithProductDto>();
    const [fastScanCode, setFastScanCode] = useState('');
    const [autoCloseFastScan, setAutoCloseFastScan] = useState(false);

    const fastScan = useFastCodeScan(shipment.assignments, fastScanCode, {
        findCode: query => itemsService.getItemByCode(query).catch(() => null),
        orderFound: setScanItem,
    });

    const canAssignItem =
        isShipmentFromHofyWarehouse(shipment) &&
        [ShipmentStatus.Created, ShipmentStatus.Booked, ShipmentStatus.Backorder].includes(shipment.status) &&
        canChangeShipment;

    const showFastScan = canAssignItem && !scanItem;

    return (
        <Box marginVertical={20} gap={20} column>
            {showFastScan && (
                <FormSection
                    label={`Fast order scanning (${
                        shipment.assignments.filter(a => !a.item || a.assignedItemNeedsScanning).length
                    } assignments left)`}
                >
                    <Box row gap={5} alignItems='flex-end'>
                        <FormInput
                            label='Item code or #Id'
                            placeholder='Enter or scan a item code'
                            onChangeText={code => setFastScanCode(code)}
                            value={fastScanCode}
                            isError={!!fastScan.errorMessage}
                            errorMessage={fastScan.errorMessage}
                            autoFocus
                            flex={1}
                        />
                        <LabeledSwitch
                            marginBottom={10}
                            label='Auto-close'
                            checked={autoCloseFastScan}
                            onChange={setAutoCloseFastScan}
                        />
                    </Box>
                </FormSection>
            )}
            <Box shrink={1} overflow='auto'>
                <FormSection label='Orders'>
                    {shipment.assignments.map((assignment, index) => (
                        <RowItem
                            id={assignment.id}
                            key={assignment.id}
                            isLast={index === shipment.assignments.length - 1}
                        >
                            <ProductItem
                                image={assignment.variant.image?.url}
                                label={
                                    <Paragraph3 row>
                                        <ItemDetails
                                            iconSize={20}
                                            itemId={assignment.item?.id}
                                            iconColor={itemIconColor(
                                                assignment.item !== null,
                                                assignment.assignedItemNeedsScanning,
                                            )}
                                            marginRight={10}
                                        />
                                        {assignment.product.name}
                                    </Paragraph3>
                                }
                                subLabel={<AssignmentSubLabel assignment={assignment} />}
                            />
                            {isShipmentFromHofyWarehouse(shipment) && (
                                <Button
                                    type='secondary'
                                    label='Scan Item'
                                    disabled={!canAssignItem}
                                    onClick={() => setScanItem(assignment)}
                                />
                            )}
                        </RowItem>
                    ))}
                </FormSection>
            </Box>

            {scanItem &&
                isShipmentFromHofyWarehouseToUser(shipment) &&
                isAdminHofyWarehouse(shipment.fromWarehouse) && (
                    <ScanItemSlideout
                        autoClose={autoCloseFastScan}
                        assignment={scanItem}
                        organizationId={shipment.toUser.organizationId}
                        warehouse={shipment.fromWarehouse}
                        country={shipment.toAddress?.country}
                        onClose={() => {
                            setScanItem(undefined);
                            setFastScanCode('');
                        }}
                        scannedCode={fastScanCode}
                    />
                )}
        </Box>
    );
};

const itemIconColor = (isAssigned: boolean, isScanRequired: boolean) => {
    if (isScanRequired) {
        return Color.AccentYellow;
    }

    if (isAssigned) {
        return Color.FoundationPositive;
    }

    return Color.ContentTertiary;
};

interface AssignmentSubLabelProps {
    assignment: AssignmentWithProductDto;
}

const AssignmentSubLabel: FC<AssignmentSubLabelProps> = ({ assignment }) => (
    <>
        <Paragraph3 color={Color.ContentTertiary}>{formatVariant(assignment.variant)}</Paragraph3>
        <Paragraph3 color={Color.ContentPrimary} marginTop={4}>
            Code:{' '}
            {assignment.item?.itemCodes ? (
                <Span color={Color.FoundationPositive}>
                    {(assignment.item.itemCodes || []).map(c => c.code).join(', ')}
                </Span>
            ) : (
                '--'
            )}
        </Paragraph3>
    </>
);

interface RowItemProps {
    id: number;
    isLast?: boolean;
    children?: ReactNode;
}

const RowItem: FC<RowItemProps> = ({ id, isLast, children }) => (
    <Box borderBottom={!isLast} row justify='space-between' paddingVertical={15}>
        <Box width={95} row>
            <Paragraph3 pointer marginLeft={5} underline>
                <AssignmentDetailsLink id={id}>#{id}</AssignmentDetailsLink>
            </Paragraph3>
        </Box>
        <Box flex={1} row justify='space-between'>
            {children}
        </Box>
    </Box>
);
