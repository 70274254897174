import React from 'react';

import { Tab, Tabs } from '@hofy/ui';

import { InventoryTab } from '../../store/purchaseOrders/types/InventoryTab';
import { useAdminNavigateInventory } from '../../store/purchaseOrders/useAdminNavigateInventory';
import { useTrAdminInventory } from '../../store/purchaseOrders/useTrAdminInventory';

interface InventoryTabsProps {
    active: InventoryTab;
}

export const InventoryTabs = ({ active }: InventoryTabsProps) => {
    const trAdminInventory = useTrAdminInventory();
    const { adminInventoryTabs, navigateInventory } = useAdminNavigateInventory();

    return (
        <Tabs active={active} onChange={navigateInventory} animationId='inventory'>
            {adminInventoryTabs.map(tab => (
                <Tab key={tab} id={tab} label={trAdminInventory(tab)} />
            ))}
        </Tabs>
    );
};
