import { emptyFulfilmentCountrySettingsFilter, FulfilmentCountrySettingsFilter } from '@hofy/api-admin';
import { useFilters } from '@hofy/ui';

import { useCountryFilterDefinition } from '../filters/useCountryFilterDefinition';
import { useSearchFilterDefinition } from '../filters/useSearchFilterDefinition';
import { useWarehousesFilterDefinition } from './filters/useWarehousesFilterDefinition';

export const useFulfilmentCountrySettingFilters = () => {
    const search = useSearchFilterDefinition();
    const countries = useCountryFilterDefinition();
    const defaultDeliverFromWarehouseIds = useWarehousesFilterDefinition('Default delivery');
    const defaultCollectToWarehouseIds = useWarehousesFilterDefinition('Default collection');
    const storeAndReuseCollectToLocalWarehouseIds =
        useWarehousesFilterDefinition('Store and reuse local hub');
    const storeAndReuseCollectToHubWarehouseIds = useWarehousesFilterDefinition('Store and reuse main hub');

    return useFilters<FulfilmentCountrySettingsFilter>(
        {
            search,
            countries,
            defaultDeliverFromWarehouseIds,
            defaultCollectToWarehouseIds,
            storeAndReuseCollectToLocalWarehouseIds,
            storeAndReuseCollectToHubWarehouseIds,
        },
        emptyFulfilmentCountrySettingsFilter,
    );
};
