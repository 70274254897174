import {
    AddressDto,
    AuditableModelDeprecatedDto,
    EmailDto,
    EmailType,
    ItPolicyRefDto,
    OrganizationRefDto,
    Role,
    UserRefDto,
    UserStatus,
    ZendeskStatusDto,
} from '@hofy/api-shared';
import { DateString, DateTimeString, UUID } from '@hofy/global';

export interface UserDto extends AuditableModelDeprecatedDto {
    uuid: UUID;
    firstName: string;
    lastName: string;
    address: AddressDto;
    startsOn: DateString | null;
    roles: Role[];
    pictureUrl: string | null;
    emails: EmailDto[];
    lastLogin: DateTimeString | null;
    firstLogin: DateTimeString | null;
    status: UserStatus;
    organization: OrganizationRefDto;
    itPolicy: ItPolicyRefDto;
    zendesk: ZendeskStatusDto;
    deel: UserDeelSyncDto;
    slackId: string | null;
    identities: IdentityDto[];
}

export interface IdentityDto {
    organization: OrganizationRefDto;
    user: UserRefDto;
}

export interface UserDeelSyncDto {
    lastUpdatedAt: DateTimeString | null;
    hrisProfileId: UUID | null;
    profileId: UUID | null;
    isShielded: boolean;
}

export const getInvitedEmailType = (user?: UserDto) => {
    if (!user) {
        return null;
    }
    const invitedEmails = user.emails.filter(u => u.invitedAt !== null);
    if (invitedEmails.length === 0) {
        return null;
    }
    return invitedEmails[0].type;
};

export const toLoginEmailAware = (user: UserDto) => {
    const personalEmail = user.emails.find(v => v.type === EmailType.Personal);
    const workEmail = user.emails.find(v => v.type === EmailType.Work);
    return {
        personalEmail: personalEmail?.address || null,
        personalEmailLogin: personalEmail?.loginEnabled || false,
        workEmail: workEmail?.address || null,
        workEmailLogin: workEmail?.loginEnabled || false,
    };
};
