import { useMutation, useQueryClient } from '@tanstack/react-query';
import { noop } from 'lodash';

import { repairsCacheKey, repairsService, UpdateRepairAssigneePayload } from '@hofy/api-admin';

export const useUpdateRepairAssignee = (id: number, onSuccess = noop) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (p: UpdateRepairAssigneePayload) => repairsService.updateRepairAssignee(id, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [repairsCacheKey] });
            onSuccess();
        },
    });

    return {
        assignRepair: (assignedUserId: number | null) => mutation.mutate({ assignedUserId }),
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
