import React, { FC } from 'react';

import { SalesOrderDetailsDto } from '@hofy/api-admin';
import { AddressDto, useTrSalesOrderStatus } from '@hofy/api-shared';
import { formatDateTime } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { Box, InfoTooltipIcon, Labeled, LabeledText, Link, Paragraph3 } from '@hofy/ui';

import { AddressTooltipOverlay } from '../../../../components/domain/address/AddressTooltipOverlay';
import { AttachmentCell, AttachmentFile } from '../../../../components/domain/invoicing/AttachmentCell';
import { AdminNavLink } from '../../../../components/routing/AdminNavLink';
import { useDownloadSalesOrder } from '../../../../store/salesOrders/useDownloadSalesOrder';

interface SalesOrderDetailsInfoProps {
    salesOrder: SalesOrderDetailsDto;
}

export const SalesOrderDetailsInfo: FC<SalesOrderDetailsInfoProps> = ({ salesOrder }) => {
    const { organization, publicId, createdAt, status, total, totalGross, deliveryAddress, shippingAddress } =
        salesOrder;
    const { formatPrice } = usePrice();
    const trSalesOrderStatus = useTrSalesOrderStatus();
    return (
        <>
            <Labeled
                label='Organization'
                content={
                    <Link to={`${AdminNavLink.Organizations}/${organization.id}/details`}>
                        {organization.name}
                    </Link>
                }
            />
            <LabeledText label='Public id' content={publicId} />
            <LabeledText label='Created at' content={formatDateTime(createdAt)} />
            <LabeledText label='Status' content={trSalesOrderStatus(status)} />
            <LabeledText label='Total price' content={formatPrice(total)} />
            <Labeled
                label='Total gross price'
                content={<Paragraph3 bold>{formatPrice(totalGross)}</Paragraph3>}
            />
            <Labeled label='Delivery address' content={<AddressTooltipInfo address={deliveryAddress} />} />
            <Labeled
                label='Shipping address'
                content={shippingAddress && <AddressTooltipInfo address={shippingAddress} />}
            />
            <Labeled label='Attachment' content={<SalesOrderFileCell salesOrder={salesOrder} />} />
        </>
    );
};

interface AddressTooltipInfoProps {
    address: AddressDto;
}

const AddressTooltipInfo: FC<AddressTooltipInfoProps> = ({ address }) => {
    return (
        <Box row gap={8}>
            <Paragraph3>Address</Paragraph3>
            <InfoTooltipIcon
                placement='top'
                bodySlot={<AddressTooltipOverlay address={address} />}
                maxWidth='auto'
                interactive
            />
        </Box>
    );
};

interface SalesOrderFileCellProps {
    salesOrder: SalesOrderDetailsDto;
}

const SalesOrderFileCell: FC<SalesOrderFileCellProps> = ({ salesOrder }) => {
    const { download, isDownloadSalesOrderLoading } = useDownloadSalesOrder();

    const salesOrderFiles: AttachmentFile[] = salesOrder.fileName
        ? [
              {
                  id: salesOrder.id,
                  filename: salesOrder.fileName,
              },
          ]
        : [];

    return (
        <AttachmentCell
            files={salesOrderFiles}
            isDownloading={isDownloadSalesOrderLoading}
            onDownload={download}
        />
    );
};
