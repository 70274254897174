import { union } from 'lodash';

import { PageRequest, PageResponse, regionToCountries, stringifyUrl } from '@hofy/global';
import { downloadFileFromResponse } from '@hofy/helpers';
import { restClient } from '@hofy/rest';

import { AppendAssignmentsToShipmentPayload } from './types/AppendAssignmentsToShipmentPayload';
import { AssignUserToShipmentPayload } from './types/AssignUserToShipmentPayload';
import { BackorderShipmentPayload } from './types/BackorderShipmentPayload';
import { BookShipmentPayload } from './types/BookShipmentPayload';
import { CancelShipmentPayload } from './types/CancelShipmentPayload';
import { CompleteShipmentPayload } from './types/CompleteShipmentPayload';
import {
    CreateCollectionShipmentPayload,
    CreateDeliveryShipmentPayload,
    CreateDropshippingShipmentPayload,
    CreateTransferShipmentPayload,
} from './types/CreateShipmentPayload';
import { ListShipmentsFilter } from './types/ListShipmentsFilterPayload';
import { RemoveAssignmentsFromShipmentPayload } from './types/RemoveAssignmentsFromShipmentPayload';
import { ShipmentCollectionRequestDto } from './types/ShipmentCollectionRequestDto';
import { ShipmentDetailsDto } from './types/ShipmentDetailsDto';
import { ShipmentDto } from './types/ShipmentDto';
import { ShipShipmentPayload } from './types/ShipShipmentPayload';
import { UpdateShipmentPayload } from './types/UpdateShipmentPayload';

class ShipmentService {
    public createDeliveryShipment = (payload: CreateDeliveryShipmentPayload): Promise<number> => {
        return restClient
            .postJson<{
                id: number;
            }>('/api/admin/shipments/delivery', payload)
            .then(r => r.id);
    };

    public createCollectionShipment = (payload: CreateCollectionShipmentPayload): Promise<number> => {
        return restClient
            .postJson<{
                id: number;
            }>('/api/admin/shipments/collection', payload)
            .then(r => r.id);
    };

    public createDropshippingShipment = (payload: CreateDropshippingShipmentPayload): Promise<number> => {
        return restClient
            .postJson<{
                id: number;
            }>('/api/admin/shipments/dropshipping', payload)
            .then(r => r.id);
    };

    public createTransferShipment = (payload: CreateTransferShipmentPayload): Promise<number> => {
        return restClient
            .postJson<{
                id: number;
            }>('/api/admin/shipments/transfer', payload)
            .then(r => r.id);
    };

    public bookShipment = (id: number, payload: BookShipmentPayload): Promise<void> => {
        return restClient.put(`/api/admin/shipments/${id}/status/booked`, payload);
    };

    public shipShipment = (id: number, payload: ShipShipmentPayload): Promise<void> => {
        return restClient.put(`/api/admin/shipments/${id}/status/with_courier`, payload);
    };

    public cancelShipment = (id: number, payload: CancelShipmentPayload): Promise<void> => {
        return restClient.put(`/api/admin/shipments/${id}/status/canceled`, payload);
    };

    public appendAssignmentsToShipment = (
        id: number,
        payload: AppendAssignmentsToShipmentPayload,
    ): Promise<void> => {
        return restClient.put(`/api/admin/shipments/${id}/append-assignments`, payload);
    };

    public removeAssignmentsFromShipment = (
        id: number,
        payload: RemoveAssignmentsFromShipmentPayload,
    ): Promise<void> => {
        return restClient.put(`/api/admin/shipments/${id}/remove-assignments`, payload);
    };

    public completeShipment = (id: number, payload: CompleteShipmentPayload): Promise<void> => {
        return restClient.put(`/api/admin/shipments/${id}/status/completed`, payload);
    };

    public backorderShipment = (id: number, payload: BackorderShipmentPayload): Promise<void> => {
        return restClient.put(`/api/admin/shipments/${id}/status/backorder`, payload);
    };

    public updateShipment = (id: number, payload: UpdateShipmentPayload): Promise<void> => {
        return restClient.put(`/api/admin/shipments/${id}`, payload);
    };

    public getShipment = (id: number): Promise<ShipmentDetailsDto> => {
        return restClient.getJson<ShipmentDetailsDto>(`/api/admin/shipments/${id}`);
    };

    public getShipmentCollectionRequest = (id: number): Promise<ShipmentCollectionRequestDto | null> => {
        return restClient.getJson<ShipmentCollectionRequestDto | null>(
            `/api/admin/shipments/${id}/collection-request`,
        );
    };

    public updateShipmentAddressToCollectionUserAddress = (id: number): Promise<void> => {
        return restClient.put(`/api/admin/shipments/${id}/collection-request/update-address`);
    };

    public markCollectionRequestItemAsFound = (
        shipmentId: number,
        collectionRequestItemId: number,
    ): Promise<void> => {
        return restClient
            .put(`/api/admin/shipments/${shipmentId}/collection-request/${collectionRequestItemId}/found`)
            .then();
    };

    public removeCollectionRequestItemFromShipment = (
        shipmentId: number,
        collectionRequestItemId: number,
    ): Promise<void> => {
        return restClient
            .put(`/api/admin/shipments/${shipmentId}/collection-request/${collectionRequestItemId}/remove`)
            .then();
    };

    public listShipments = (
        filters: ListShipmentsFilter,
        page: PageRequest,
    ): Promise<PageResponse<ShipmentDto>> => {
        const regionCountries = filters.region?.flatMap(regionToCountries);
        return restClient.getJson<PageResponse<ShipmentDto>>(
            stringifyUrl({
                url: `/api/admin/shipments`,
                query: {
                    page: page.page,
                    pageSize: page.pageSize,
                    status: filters.shipmentStatus,
                    shipmentTypes: filters.shipmentTypes,
                    assignedUser: filters.assignedUser,
                    toUserId: filters.toUserId,
                    fromUserId: filters.fromUserId,
                    shipToday: filters.shipToday,
                    country: union(filters.country, regionCountries),
                    warehouse: filters.warehouse,
                    shouldUpdate: filters.shouldUpdate,
                    trackingProviders: filters.shipmentTrackingProviders,
                    withTrackingException: filters.withTrackingException,
                    withoutTracking: filters.withoutTracking,
                    search: filters.search,
                },
            }),
        );
    };

    public exportShipmentLabel = async (ids: number[]): Promise<void> => {
        await restClient
            .downloadFile(
                stringifyUrl({
                    url: `/api/admin/shipments/report`,
                    query: {
                        shipmentIds: ids,
                    },
                }),
            )
            .then(downloadFileFromResponse);
    };

    public assignUserToShipment = (id: number, payload: AssignUserToShipmentPayload): Promise<void> => {
        return restClient.put(`/api/admin/shipments/${id}/assign`, payload);
    };

    public clearTrackingExceptionRequest = async (shipmentId: number): Promise<void> =>
        restClient.post(`/api/admin/shipments/${shipmentId}/clear-tracking-exception`);

    public enableTrackingRequest = async (shipmentId: number): Promise<void> =>
        restClient.post(`/api/admin/shipments/${shipmentId}/enable-tracking`);
}

export const shipmentService = new ShipmentService();
