import React, { FC, useState } from 'react';

import { ShipmentCollectionRequestItemDto, ShipmentDetailsDto } from '@hofy/api-admin';
import { Color } from '@hofy/theme';
import { Alert, Button, ConfirmModal, Modals, SectionTitle3, Span } from '@hofy/ui';

import { useMarkCollectionRequestItemAsFound } from '../../../../../store/shipments/useMarkCollectionRequestItemAsFound';
import { useRemoveCollectionRequestItemFromShipment } from '../../../../../store/shipments/useRemoveCollectionRequestItemFromShipment';
import { Hoverable } from '../../../../assignmentsPage/components/utils/Hoverable';

interface MissingItemActionProps {
    shipment: ShipmentDetailsDto;
    missingItems: ShipmentCollectionRequestItemDto[];
    onAssignmentClick(assignmentId: number): void;
}

export const MissingItemAction: FC<MissingItemActionProps> = ({
    shipment,
    missingItems,
    onAssignmentClick,
}) => {
    const { removeCollectionRequestItemFromShipment, isLoadingMutation: isRemovingItem } =
        useRemoveCollectionRequestItemFromShipment(shipment.idDeprecated);
    const { markCollectionRequestItemAsFound, isLoadingMutation: isMarkingAsFound } =
        useMarkCollectionRequestItemAsFound(shipment.idDeprecated);

    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<number>();
    const [isMarkAsFoundModalOpen, setIsMarkAsFoundModalOpen] = useState<number>();

    const handleItemRemove = (collectionRequestItemId?: number) => {
        if (collectionRequestItemId) {
            removeCollectionRequestItemFromShipment(collectionRequestItemId);
        }
    };

    const handleItemFound = (collectionRequestItemId?: number) => {
        if (collectionRequestItemId) {
            markCollectionRequestItemAsFound(collectionRequestItemId);
        }
    };

    if (missingItems.length === 0) {
        return null;
    }

    const missingItemActionItems = missingItems.map(item => {
        const clickableId = (
            <Hoverable color={Color.ContentNegative} onClick={() => onAssignmentClick(item.assignmentId)}>
                <Span color={Color.ContentNegative}>#{item.assignmentId}</Span>
            </Hoverable>
        );
        const missingItemTitle = (
            <SectionTitle3 row gap={4}>
                <Span>Item</Span>
                {clickableId}
                <Span>is missing</Span>
            </SectionTitle3>
        );
        return (
            <Alert
                type='negative'
                key={item.assignmentId}
                title={missingItemTitle}
                description={item.missingItemNote && `User note: ${item.missingItemNote}`}
            >
                <Button
                    label='Mark as found'
                    size='small'
                    type='plain'
                    onClick={() => setIsMarkAsFoundModalOpen(item.collectionRequestItemId)}
                />
                <Button
                    action='destructive'
                    label='Remove from shipment'
                    size='small'
                    type='plain'
                    onClick={() => setIsRemoveModalOpen(item.collectionRequestItemId)}
                />
            </Alert>
        );
    });

    return (
        <>
            {missingItemActionItems}
            <Modals>
                {isMarkAsFoundModalOpen !== undefined && (
                    <ConfirmModal
                        keyPrefix='mark-collection-request-item-as-found-modal'
                        onClose={() => setIsMarkAsFoundModalOpen(undefined)}
                        onConfirm={() => handleItemFound(isMarkAsFoundModalOpen)}
                        isLoading={isMarkingAsFound}
                    />
                )}
                {isRemoveModalOpen !== undefined && (
                    <ConfirmModal
                        keyPrefix='remove-assignment-from-shipment-modal'
                        onClose={() => setIsRemoveModalOpen(undefined)}
                        onConfirm={() => handleItemRemove(isRemoveModalOpen)}
                        isLoading={isRemovingItem}
                    />
                )}
            </Modals>
        </>
    );
};
