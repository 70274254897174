import { omit, xor } from 'lodash';
import { useMemo } from 'react';

import { allInitialInspectionStatuses, InspectionStatus } from '@hofy/api-shared';
import { countObjectValues } from '@hofy/helpers';
import { useArrayQueryParam, useStringQueryParam } from '@hofy/router';

import { InspectionsTab } from './inspectionsTab';

export const useInspectionsFilters = (tab: InspectionsTab) => {
    const [viewType, setViewType] = useStringQueryParam<'columns' | 'rows'>('viewType', 'columns');

    const [inspectionStatuses, setInspectionStatuses] = useArrayQueryParam<InspectionStatus>(
        'inspectionStatuses',
        allInitialInspectionStatuses,
    );
    const toggleInspectionStatus = (status: InspectionStatus) => {
        setInspectionStatuses(xor(inspectionStatuses, [status]));
    };

    const [deviceCheckStatuses, setDeviceCheckStatuses] = useArrayQueryParam<InspectionStatus>(
        'deviceCheckStatuses',
        allInitialInspectionStatuses,
    );
    const toggleDeviceCheckStatus = (status: InspectionStatus) => {
        setDeviceCheckStatuses(xor(deviceCheckStatuses, [status]));
    };

    const [warehouseIds, setWarehouseIds] = useArrayQueryParam<number>('warehouseIds');
    const toggleWarehouseId = (id: number) => {
        setWarehouseIds(xor(warehouseIds, [id]));
    };

    const [assignedToIds, setAssignedToIds] = useArrayQueryParam<number>('assignedToIds');
    const toggleAssignedToId = (id: number) => {
        setAssignedToIds(xor(assignedToIds, [id]));
    };

    const values = useMemo(() => {
        return {
            viewType,
            inspectionStatuses,
            deviceCheckStatuses,
            warehouseIds,
            assignedToIds,
        };
    }, [viewType, inspectionStatuses, deviceCheckStatuses, warehouseIds, assignedToIds]);

    const count =
        tab === InspectionsTab.Inspections
            ? countObjectValues(
                  omit(
                      values,
                      'viewType',
                      'deviceCheckStatuses',
                      viewType === 'columns' ? 'inspectionStatuses' : '',
                  ),
              )
            : countObjectValues(
                  omit(
                      values,
                      'viewType',
                      'inspectionStatuses',
                      viewType === 'columns' ? 'deviceCheckStatuses' : '',
                  ),
              );

    return {
        values,
        count,
        controls: {
            setViewType,
            setInspectionStatuses,
            setDeviceCheckStatuses,
            setAssignedToIds,
            setWarehouseIds,
            toggleInspectionStatus,
            toggleDeviceCheckStatus,
            toggleWarehouseId,
            toggleAssignedToId,
        },
    };
};
