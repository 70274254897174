import { useMutation, useQueryClient } from '@tanstack/react-query';

import { invoiceCacheKey, invoicesService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useSendEmailByInvoice = (invoiceId: number, onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (contactIds: number[]) => invoicesService.sendInvoiceByEmail(invoiceId, contactIds),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [invoiceCacheKey] });
            showToast({
                type: 'positive',
                message: 'Invoice email has been sent.',
            });
            onSuccess?.();
        },
    });

    const sendInvoice = (contactIds: number[]) => {
        mutation.mutate(contactIds);
    };

    return {
        sendInvoice,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
