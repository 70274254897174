import React, { FC, useState } from 'react';

import { InvoiceDto } from '@hofy/api-admin';
import { formatDateTime } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import {
    BooleanBadge,
    ConfirmModal,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Modals,
    Placeholder,
    SvgIllustration,
} from '@hofy/ui';
import { InvoiceStatusChip } from '@hofy/ui-domain';

import { AttachmentCell } from '../../../components/domain/invoicing/AttachmentCell';
import { InvoicePaymentStatusChip } from '../../../components/domain/invoicing/InvoicePaymentStatusChip';
import { NetsuiteStatusChip } from '../../../components/domain/netsuite/NetsuiteStatusChip';
import { useDownloadInvoice } from '../../../store/invoices/useDownloadInvoice';
import { useDeleteFile } from '../../../store/invoices/useUploadInvoice';
import { UploadInvoiceModal } from '../components/UploadInvoiceModal';

interface InvoicesTabProps {
    infinityScroll: InfiniteScrollConfig;
    invoices: InvoiceDto[];
    onEntryClick(id: number): void;
}

export const InvoicesTable: FC<InvoicesTabProps> = ({ infinityScroll, onEntryClick, invoices }) => {
    const { download, isDownloadInvoiceLoading } = useDownloadInvoice();
    const { deleteFile } = useDeleteFile();
    const [deleteFileId, setDeleteFileId] = useState<number>();
    const [uploadInvoiceId, setUploadInvoiceId] = useState<number>();
    const { formatPrice } = usePrice();

    return (
        <>
            <InfinityScrollTable
                data={invoices}
                toKey={item => item.id}
                onRowClick={item => onEntryClick(item.id)}
                infinityScroll={infinityScroll}
                emptyContent={
                    <Placeholder
                        illustration={SvgIllustration.FinanceSearch}
                        title='No invoices were found for the current search'
                    />
                }
                flex='auto'
                columns={[
                    {
                        id: 'id',
                        flexGrow: 0,
                        width: 80,
                        header: '#Id',
                        renderer: item => item.id,
                    },
                    {
                        id: 'reference',
                        flexGrow: 1,
                        header: 'Reference',
                        renderer: item => item.reference,
                    },
                    {
                        id: 'From',
                        flexGrow: 1,
                        header: 'From',
                        renderer: item => item.hofySubsidiary.name,
                    },
                    {
                        id: 'To',
                        flexGrow: 1,
                        header: 'To',
                        renderer: item => item.billingEntity.name || '--',
                    },
                    {
                        id: 'date',
                        header: 'Invoice time',
                        flexGrow: 1,
                        renderer: item => item.invoiceTime,
                    },
                    {
                        id: 'status',
                        header: 'Status',
                        flexGrow: 1,
                        renderer: item => (
                            <InvoiceStatusChip status={item.status} isCreditNote={item.isCreditNote} />
                        ),
                    },
                    {
                        id: 'paymentStatus',
                        header: 'Payment status',
                        flexGrow: 1,
                        renderer: item => <InvoicePaymentStatusChip status={item.paymentStatus} />,
                    },
                    {
                        id: 'due',
                        header: 'Total amount',
                        flexGrow: 1,
                        renderer: item => formatPrice(item.totalPrice),
                    },
                    {
                        id: 'due',
                        header: 'Balance',
                        flexGrow: 1,
                        renderer: item => formatPrice(item.balance),
                    },
                    {
                        id: 'email',
                        header: 'Emailed at',
                        renderer: item => formatDateTime(item.firstSentAt),
                    },
                    {
                        id: 'created_by_two',
                        header: 'Two API',
                        renderer: item => <BooleanBadge value={item.collectedByTwo} offColor='grey' />,
                    },
                    {
                        id: 'netsuite',
                        header: 'Netsuite',
                        flexGrow: 1,
                        renderer: item => <NetsuiteStatusChip status={item.netsuite} />,
                    },
                    {
                        id: 'attachment',
                        header: 'Attachment',
                        flexGrow: 2,
                        renderer: (item: InvoiceDto) => (
                            <AttachmentCell
                                files={item.attachment}
                                onDownload={download}
                                isDownloading={isDownloadInvoiceLoading}
                            />
                        ),
                    },
                ]}
            />
            <Modals>
                {deleteFileId && (
                    <ConfirmModal
                        keyPrefix='delete-file-modal'
                        onClose={() => setDeleteFileId(undefined)}
                        onConfirm={() => deleteFile(deleteFileId)}
                    />
                )}
                {uploadInvoiceId && (
                    <UploadInvoiceModal
                        onCancel={() => setUploadInvoiceId(undefined)}
                        invoiceId={uploadInvoiceId}
                    />
                )}
            </Modals>
        </>
    );
};
