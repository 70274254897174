import { OrganizationStatus, PlatformTier } from '@hofy/api-shared';
import { Currency } from '@hofy/global';

export interface AdminOrganizationsFilters {
    search?: string;
    statuses: OrganizationStatus[];
    currencies: Currency[];
    platformTiers: PlatformTier[];
    salesReps: number[];
    accountManagers: number[];
}

export const emptyAdminOrganizationsFilters: AdminOrganizationsFilters = {
    statuses: [],
    currencies: [],
    platformTiers: [],
    salesReps: [],
    accountManagers: [],
};
