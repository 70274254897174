import React, { FC } from 'react';

import { SettingsSwitch } from '@hofy/common';
import { Color } from '@hofy/theme';
import {
    Alert,
    Box,
    Button,
    InnerCard,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    SectionTitle3,
    SvgIcon,
} from '@hofy/ui';

import { useUploadTwoPayoutsFlag } from '../../store/payouts/useUploadTwoPayoutsFlag';

interface DevFlagsModalProps {
    onClose(): void;
}

interface FeatureSettings {
    label: string;
    isEnabled: boolean;
    onStatusChanged(v: boolean): void;
}

export const DevFlagsModal: FC<DevFlagsModalProps> = ({ onClose }) => {
    const [uploadTwoPayoutsEnabled, setUploadTwoPayoutsEnabled] = useUploadTwoPayoutsFlag();

    const experimentalFeatures: FeatureSettings[] = [
        {
            label: 'Upload Two payouts',
            isEnabled: uploadTwoPayoutsEnabled,
            onStatusChanged: setUploadTwoPayoutsEnabled,
        },
    ];

    return (
        <Modal onClose={onClose}>
            <ModalHeader title='Experimental features' />
            <ModalContent>
                <Box gap={16} column>
                    <Alert
                        type='informative'
                        title="Your choices are stored in your browser's memory"
                        description='They will be kept until modified here'
                    />
                    <Box column gap={4}>
                        {experimentalFeatures.map(({ label, isEnabled, onStatusChanged }, index) => (
                            <InnerCard key={index} row justify='space-between'>
                                <Box flex={2}>
                                    <SectionTitle3>{label}</SectionTitle3>
                                </Box>
                                <SettingsSwitch checked={isEnabled} onChange={onStatusChanged} />
                            </InnerCard>
                        ))}
                    </Box>
                    {experimentalFeatures.length === 0 && (
                        <SectionTitle3 centered color={Color.ContentPrimary}>
                            No experimental features are available at the moment
                        </SectionTitle3>
                    )}
                </Box>
            </ModalContent>
            <ModalFooter>
                <Button type='secondary' label='Close' onClick={onClose} leftIcon={SvgIcon.Cross} />
            </ModalFooter>
        </Modal>
    );
};
