import React, { FC } from 'react';

import { allIssueFilters, IssueFilter } from '@hofy/api-admin';
import { Page } from '@hofy/common';
import { PageHeader, SearchInput, Tab, Tabs } from '@hofy/ui';

import { useIssues } from '../../store/issues/useIssues';
import { useTrIssueFilter } from '../../store/issues/useTrIssueFilter';
import { IssueTable } from './components/IssueTable';

interface IssuesPageProps {
    filter: IssueFilter;
    onOpenIssue(id: number): void;
    onOpenTab(tab: IssueFilter): void;
}

export const IssuesPage: FC<IssuesPageProps> = ({ filter, onOpenIssue, onOpenTab }) => {
    const trAdminFilter = useTrIssueFilter();

    const { data: issues, isLoading, search, setSearch } = useIssues(filter);

    return (
        <Page>
            <PageHeader
                title='Issues'
                rightSlot={
                    <SearchInput value={search} onChange={setSearch} placeholder='Search issues…' autoFocus />
                }
                tabsSlot={
                    <Tabs active={filter} onChange={onOpenTab} marginTop={16}>
                        {allIssueFilters.map(tab => (
                            <Tab key={tab} id={tab} label={trAdminFilter(tab)} />
                        ))}
                    </Tabs>
                }
            />
            <IssueTable issues={issues} onOpenIssue={onOpenIssue} isLoading={isLoading} />
        </Page>
    );
};
