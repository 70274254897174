import React, { FC } from 'react';

import { formatUserName, UserRefDto } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Box, Icon, Paragraph3, SvgIcon } from '@hofy/ui';

import { UserDetailsLink } from '../../../usersPage/UserDetailsLink';

interface AssignedUserCellProps {
    user: UserRefDto;
}

export const AssignedUserCell: FC<AssignedUserCellProps> = ({ user }) => {
    return (
        <Box row gap={8}>
            <Icon size={16} svg={SvgIcon.User} color={Color.ContentSecondary} />
            <Box>
                <UserDetailsLink id={user.idDeprecated} color={Color.ContentSecondary}>
                    <Paragraph3 color={Color.ContentTertiary}>{formatUserName(user)}</Paragraph3>
                </UserDetailsLink>
            </Box>
        </Box>
    );
};
