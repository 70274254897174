import React, { FC } from 'react';

import { StockLevelCountsDto } from '@hofy/api-admin';
import { formatDate } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Box, Labeled, LabeledText, Paragraph3 } from '@hofy/ui';

import { AssignmentPendingLink } from '../../../assignmentsPage/components/AssignmentPendingLink';

interface StockLevelTooltipProps {
    stockLevel: StockLevelCountsDto;
    sku: string;
}

export const StockLevelTooltip: FC<StockLevelTooltipProps> = ({ sku, stockLevel }) => {
    return (
        <Box width={320}>
            <Paragraph3 marginBottom={6} color={Color.ContentInvertedPrimary}>
                {stockLevel.warehouse.name}
            </Paragraph3>
            <Box row justify='space-between'>
                <LabeledText label='In warehouse' content={stockLevel.inWarehouse} invertedColor />
                <LabeledText
                    label='Open orders'
                    content={
                        stockLevel.openOrders > 0 ? (
                            <AssignmentPendingLink search={sku}>
                                {stockLevel.openOrders}
                            </AssignmentPendingLink>
                        ) : (
                            stockLevel.openOrders
                        )
                    }
                    invertedColor
                />
                <LabeledText label='Available' content={stockLevel.available} invertedColor />
                <LabeledText label='Minimum' content={stockLevel.minQuantity} invertedColor />
            </Box>
            {stockLevel && !!stockLevel.purchaseOrders.length && (
                <Labeled
                    marginTop={16}
                    label='Incoming purchase orders'
                    content={
                        <Box column gap={6}>
                            <Paragraph3 row color={Color.ContentInvertedPrimary}>
                                <Box width={120}>Delivery date</Box>
                                <Box width={80}>Quantity</Box>
                                <Box row flex={1}>
                                    Supplier
                                </Box>
                            </Paragraph3>
                            {stockLevel.purchaseOrders.map(order => (
                                <Paragraph3 row color={Color.ContentInvertedSecondary} key={order.id}>
                                    <Box width={120}>{formatDate(order.deliveryOn)}</Box>
                                    <Box width={80}>{order.quantity}</Box>
                                    <Box row flex={1}>
                                        {order.supplier}
                                    </Box>
                                </Paragraph3>
                            ))}
                        </Box>
                    }
                    invertedColor
                />
            )}
        </Box>
    );
};
