import { useMutation } from '@tanstack/react-query';

import { dataSeedService, emptySeedRandomUsersPayload, SeedRandomUsersPayload } from '@hofy/api-admin';
import { isGreaterThan, isRequired, isValidEmailAddress, useForm, useToast, validator } from '@hofy/ui';

export const useSeedUsers = () => {
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: dataSeedService.seedRandomUsers,
        onSuccess: async () => {
            showToast({
                type: 'positive',
                message: 'Users generated',
            });
        },
    });

    const form = useForm<SeedRandomUsersPayload>({
        initial: emptySeedRandomUsersPayload,
        onSubmit: mutation.mutate,
        validate: validator<SeedRandomUsersPayload>({
            organizationId: [isRequired('Organization is required'), isGreaterThan(0, 'Count must be valid')],
            count: isGreaterThan(0, 'Count must be valid'),
            baseEmail: [
                isRequired('Base email is required'),
                isValidEmailAddress('Valid base email is required'),
            ],
        }),
    });
    return { form, isLoading: mutation.isPending };
};
