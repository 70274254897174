import { useMutation, useQueryClient } from '@tanstack/react-query';

import { emailLogUserCacheKey, usersCacheKey, userService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useDeleteUserPersonalData = (userId: number) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: userService.deleteUser,
        onSuccess: async () => {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: [usersCacheKey, userId] }),
                queryClient.invalidateQueries({ queryKey: emailLogUserCacheKey(userId) }),
            ]);
            showToast({
                type: 'positive',
                message: 'User personal data successfully deleted',
            });
        },
    });

    const deleteUserPersonalData = () => {
        mutation.mutate(userId);
    };

    return { deleteUserPersonalData };
};
