import React, { FC } from 'react';

import { useIssueQuery } from '@hofy/api-admin';
import { ComponentLoader } from '@hofy/common';

import { IssueDetailsPage } from './IssueDetailsPage';

interface IssueDetailsOverlayProps {
    issueId: number;
}

export const IssueDetailsOverlay: FC<IssueDetailsOverlayProps> = ({ issueId }) => {
    const { data, isLoading } = useIssueQuery(issueId);

    if (!data || isLoading) {
        return <ComponentLoader />;
    }

    return <IssueDetailsPage issue={data} />;
};
