import { useQuery } from '@tanstack/react-query';

import { AssignmentStatus } from '@hofy/api-shared';

import { assignmentService } from '../service/assignmentService';
import { AssignmentWithProductDto } from '../service/types/AssignmentWithProductDto';
import { userAssignmentCacheKey } from './cacheKey';

const emptyList: AssignmentWithProductDto[] = [];

export const useUserAssignmentsQuery = (userId: number, status?: AssignmentStatus[]) => {
    const { data, isLoading } = useQuery({
        queryKey: userAssignmentCacheKey(userId, status),

        queryFn: () => assignmentService.getUserAssignments(userId, status),
    });

    return {
        data: data ?? emptyList,
        isLoading,
    };
};
