import { useQuery } from '@tanstack/react-query';

import { emailLogService } from '../emailLogService';
import { emailLogCacheKey } from './cacheKey';

export const useEmailLogQuery = (emailId: number) => {
    const { isLoading, data: email } = useQuery({
        queryKey: [emailLogCacheKey, emailId],
        queryFn: () => emailLogService.getEmail(emailId),
    });

    return {
        email,
        isLoading,
    };
};
