import React, { FC, ReactNode } from 'react';

import { Color } from '@hofy/theme';

import { getUserLink } from '../../components/routing/adminLinks';
import { Link } from '../../components/routing/Link';

interface UserDetailsLinkProps {
    id: number;
    color?: Color;
    children?: ReactNode;
}

export const UserDetailsLink: FC<UserDetailsLinkProps> = ({ id, color, children }) => {
    return (
        <Link color={color} to={getUserLink(id)}>
            {children}
        </Link>
    );
};
