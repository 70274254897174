import { anyCategoryToList, RentalStoreAndReuseFeesDto } from '@hofy/api-shared';
import { UpdateContractExpirySettingsPayload } from '@hofy/api-storefront-rest';
import { PageRequest, PageResponse, stringifyUrl, UUID } from '@hofy/global';
import { downloadFileFromResponse } from '@hofy/helpers';
import { restClient } from '@hofy/rest';

import { ActivateContractPayload } from './types/ActivateContractPayload';
import { CancelContractPayload } from './types/CancelContractPayload';
import { ContractDetailsUnionDto } from './types/ContractDetailsDto';
import { ContractUnionDto } from './types/ContractDto';
import { ContractEndPredictionDto } from './types/ContractEndPredictionDto';
import { ContractFilters, ContractFiltersUi } from './types/ContractFilters';
import { ContractTabFilter } from './types/ContractTabFilter';
import { CreateCustomerOwnedAssetPayload } from './types/CreateCustomerOwnedAssetPayload';
import { EndContractPayload } from './types/EndContractPayload';
import { EnrollItemsIntoServicePackagePayload } from './types/EnrollItemsIntoServicePackagePayload';
import { EnrollItemValidationDetailsDto } from './types/EnrollItemValidationDetailsDto';
import { RolloverContractPayload } from './types/RolloverContractPayload';
import { isStoreAndReuseChildFilter, storeAndReuseStatusToPayload } from './types/StoreAndReuseStatusFilter';
import {
    AutoUpdateContractPayload,
    UpdateContractPayload,
    UpdatePurchaseContractPayload,
} from './types/UpdateContractPayload';
import { UpdateTermAndConditionPayload } from './types/UpdateTermAndConditionPayload';

class ContractService {
    public getContract = async (id: number): Promise<ContractDetailsUnionDto> => {
        return restClient.getJson<ContractDetailsUnionDto>(`/api/admin/contracts/${id}`);
    };

    public listContracts = (
        { tab, productCategory, ...filters }: ContractFiltersUi,
        page: PageRequest,
    ): Promise<PageResponse<ContractUnionDto>> => {
        const payload: ContractFilters = {
            ...page,
            search: filters.search,
            status: filters.status || [],
            organizations: filters.organizations || [],
            contractType: filters.contractType || [],
            storeAndReuseStatus: storeAndReuseStatusToPayload(filters.storeAndReuseStatus),
            storeAndReuseDeliveryCountry: filters.storeAndReuseDeliveryCountry,
            isStoreAndReuseChild: isStoreAndReuseChildFilter(filters.storeAndReuseStatus),
            productId: filters.productId || null,
            productCategory: productCategory ? anyCategoryToList(productCategory) : null,
            expiredOnly: tab === ContractTabFilter.Expired,
            isRedistributableOrReusable: filters.redistributable ?? null,
        };

        return restClient.getJson<PageResponse<ContractUnionDto>>(
            stringifyUrl({
                url: `/api/admin/contracts`,
                query: {
                    ...payload,
                },
            }),
        );
    };

    public downloadContractsReport = (filters: ContractFiltersUi) => {
        return restClient
            .downloadFile(
                stringifyUrl({
                    url: '/api/admin/contracts/report',
                    query: {
                        ...filters,
                    },
                }),
            )
            .then(downloadFileFromResponse);
    };

    public endContract = async (id: number, payload: EndContractPayload): Promise<void> => {
        return restClient.post(`/api/admin/contracts/${id}/end`, payload);
    };

    public getEndContractPrediction = async (
        id: number,
        endDate: string,
    ): Promise<ContractEndPredictionDto> => {
        return restClient.getJson<ContractEndPredictionDto>(
            stringifyUrl({
                url: `/api/admin/contracts/${id}/end-prediction`,
                query: {
                    endDate,
                },
            }),
        );
    };

    public getRentalStoreAndReuseFee = async (contractId: number): Promise<RentalStoreAndReuseFeesDto> => {
        return restClient.getJson<RentalStoreAndReuseFeesDto>(
            stringifyUrl({
                url: `/api/admin/contracts/${contractId}/store-and-reuse-fee`,
            }),
        );
    };

    public rolloverContract = async (id: number, payload: RolloverContractPayload): Promise<void> => {
        return restClient.post(`/api/admin/contracts/${id}/rollover`, payload);
    };

    public updateContract = async (id: number, payload: UpdateContractPayload): Promise<number> => {
        return restClient
            .postJson<{
                id: number;
            }>(`/api/admin/contracts/${id}/edit-rental`, payload)
            .then(t => t.id);
    };

    public updatePurchaseContract = async (
        id: number,
        payload: UpdatePurchaseContractPayload,
    ): Promise<number> => {
        return restClient
            .postJson<{
                id: number;
            }>(`/api/admin/contracts/${id}/edit-purchase`, payload)
            .then(t => t.id);
    };

    public updateContractWithCredit = async (id: number, payload: UpdateContractPayload): Promise<number> => {
        return restClient
            .postJson<{
                id: number;
            }>(`/api/admin/contracts/${id}/with-credit`, payload)
            .then(t => t.id);
    };

    public autoUpdateContract = async (id: number, payload: AutoUpdateContractPayload): Promise<number> => {
        return restClient
            .postJson<{
                id: number;
            }>(`/api/admin/contracts/${id}/auto`, payload)
            .then(t => t.id);
    };

    public activateContract = async (id: number, payload: ActivateContractPayload): Promise<void> => {
        return restClient.post(`/api/admin/contracts/${id}/activate`, payload);
    };

    public cancelContract = async (id: number, payload: CancelContractPayload): Promise<void> => {
        return restClient.post(`/api/admin/contracts/${id}/cancel`, payload);
    };

    public creditContract = async (id: number): Promise<void> => {
        return restClient.post(`/api/admin/contracts/${id}/credit`, {});
    };

    public revertEndContract = async (id: number): Promise<void> => {
        return restClient.post(`/api/admin/contracts/${id}/revert-end`, {});
    };

    public updateContractsTermAndCondition = async (id: number, payload: UpdateTermAndConditionPayload) => {
        return restClient.put(`/api/admin/contracts/term-and-condition/${id}`, payload);
    };

    public createCustomerOwnedAsset = async (payload: CreateCustomerOwnedAssetPayload) => {
        return restClient.put(`/api/admin/contracts/create-customer-owned-asset`, payload);
    };

    public updateBillingEntity = async (id: number, billingEntityId: number) => {
        return restClient.put(`/api/admin/contracts/${id}/billing-entity/${billingEntityId}`);
    };

    public updateContractExpirySettings = (id: UUID, payload: UpdateContractExpirySettingsPayload) => {
        return restClient.put(`/api/admin/contracts/${id}/contract-expiry-settings`, payload);
    };

    public enrollItemsIntoServicePackage = (payload: EnrollItemsIntoServicePackagePayload): Promise<void> => {
        return restClient.put(`/api/admin/contracts/enroll-items`, payload);
    };

    public validateEnrollItemsIntoServicePackage = (
        payload: EnrollItemsIntoServicePackagePayload,
    ): Promise<EnrollItemValidationDetailsDto[]> => {
        return restClient.postJson<EnrollItemValidationDetailsDto[]>(
            `/api/admin/contracts/validate-enroll-items`,
            payload,
        );
    };
}

export const contractService = new ContractService();
