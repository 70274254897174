import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, assignmentService, SubstituteProductPayload } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useGoBack } from '@hofy/hooks';
import { useToast } from '@hofy/ui';

export const useSubstituteProduct = (id: number, basePath: string) => {
    const { showToast } = useToast();
    const { goBack } = useGoBack();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (p: SubstituteProductPayload) => assignmentService.substituteProduct(id, p),
        onSuccess: () => {
            goBack(basePath);
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Order product replaced',
            });
        },
    });

    const substitute = (variantId: UUID) => {
        mutation.mutate({ variantId });
    };

    return {
        isLoading: mutation.isPending,
        substitute,
    };
};
