import React, { FC, MouseEvent } from 'react';
import styled from 'styled-components';

import { InvoiceFileDto } from '@hofy/api-shared';
import { Box, TextCell } from '@hofy/ui';

export interface AttachmentFile {
    id: number;
    filename: string;
}

interface AttachmentCellProps {
    files: AttachmentFile[];
    isDownloading: boolean;
    onDownload(fileId: number): void;
}

export const AttachmentCell: FC<AttachmentCellProps> = ({ files, onDownload, isDownloading }) => {
    const handleDownload = (e: MouseEvent<HTMLDivElement>, f: InvoiceFileDto) => {
        e.stopPropagation();
        onDownload(f.id);
    };
    if (!files.length) {
        return <TextCell>--</TextCell>;
    }
    return (
        <TextCell>
            {files.map(f => (
                <Box key={f.id} row>
                    <LinkWrapper key={f.id} onClick={e => !isDownloading && handleDownload(e, f)}>
                        {f.filename}
                    </LinkWrapper>
                </Box>
            ))}
        </TextCell>
    );
};

const LinkWrapper = styled(Box)`
    cursor: pointer;

    :hover {
        text-decoration: underline;
    }
`;
