import { useUserQuery } from '@hofy/api-admin';

export const useUser = (id: number | null) => {
    const { data: user, isLoading } = useUserQuery(id);

    return {
        user,
        isLoading,
    };
};
