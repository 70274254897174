import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateOrganizationPayload, OrganizationManager, organizationService } from '@hofy/api-admin';
import { OrganizationType, PlatformTier } from '@hofy/api-shared';
import { Country, Currency } from '@hofy/global';
import { errorMap, isEmpty, isInvalidEmail } from '@hofy/helpers';
import { useForm, useToast } from '@hofy/ui';

import { organizationsCacheKey } from './organizationsCacheKey';

const validateOrganizationForm = (formState: CreateOrganizationFormData) => {
    const { name, currency, managerFirstName, managerLastName, managerCountry, managerEmail, createManager } =
        formState;

    return {
        name: errorMap([isEmpty(name), 'Organization name is required']),
        currency: errorMap([isEmpty(currency), 'Currency is required']),
        managerFirstName: errorMap([
            createManager && isEmpty(managerFirstName),
            'Manager first name is required',
        ]),
        managerLastName: errorMap([
            createManager && isEmpty(managerLastName),
            'Manager last name is required',
        ]),
        managerCountry: errorMap([createManager && isEmpty(managerCountry), 'Manager country is required']),
        managerEmail: errorMap(
            [createManager && isEmpty(managerEmail), 'Manager email is required'],
            [createManager && isInvalidEmail(managerEmail), 'Manager email is invalid'],
        ),
    };
};

export interface CreateOrganizationFormData {
    name: string;
    currency: Currency | null;
    organizationType: OrganizationType;
    platformTier: PlatformTier;

    successAdminId: number | null;
    salesAdminId: number | null;

    seedProducts: boolean;
    seedWithFurniture: boolean;
    createManager: boolean;
    managerFirstName: string;
    managerLastName: string;
    managerEmail: string;
    managerCountry: Country | null;
    managerSendInvite: boolean;
}

export const emptyOrganizationFormData: CreateOrganizationFormData = {
    name: '',
    currency: null,
    seedProducts: true,
    seedWithFurniture: false,
    organizationType: OrganizationType.Standard,
    platformTier: PlatformTier.OrgBasedPro,
    successAdminId: null,
    salesAdminId: null,
    createManager: false,
    managerFirstName: '',
    managerLastName: '',
    managerEmail: '',
    managerCountry: null,
    managerSendInvite: true,
};

export const useCreateOrganization = (onClose: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (f: CreateOrganizationFormData) =>
            organizationService.createOrganization(createOrganizationToPayload(f)),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Organisation successfully created',
            });
            onClose();
        },
    });

    const form = useForm<CreateOrganizationFormData>({
        initial: emptyOrganizationFormData,
        onSubmit: mutation.mutate,
        validate: validateOrganizationForm,
    });

    return {
        form,
        isLoadingMutation: mutation.isPending,
    };
};

const createOrganizationToPayload = (formData: CreateOrganizationFormData): CreateOrganizationPayload => {
    const isStandard = formData.organizationType === OrganizationType.Standard;
    const isDemo = formData.organizationType === OrganizationType.Demo;

    const seedProducts = isStandard ? formData.seedProducts : false;
    const seedProductsWithFurniture = isStandard ? formData.seedWithFurniture : false;

    const organizationType = isDemo ? OrganizationType.Standard : formData.organizationType;

    return {
        name: formData.name,
        currency: formData.currency,
        organizationType: organizationType,
        platformTier: formData.platformTier,
        seedProducts: seedProducts,
        seedProductsWithFurniture: seedProductsWithFurniture,
        manager: createOrganizationManagerPayload(formData),
        successAdminId: formData.successAdminId,
        salesAdminId: formData.salesAdminId,
    };
};

const createOrganizationManagerPayload = (
    formData: CreateOrganizationFormData,
): OrganizationManager | null => {
    if (!formData.createManager) {
        return null;
    }
    return {
        firstName: formData.managerFirstName,
        lastName: formData.managerLastName,
        email: formData.managerEmail,
        country: formData.managerCountry,
        sendInvite: formData.managerSendInvite,
    };
};
