import { useMutation, useQueryClient } from '@tanstack/react-query';

import { usersCacheKey, userService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useSyncUserToZendesk = (userId: number) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: userService.syncToZendesk,
        onSuccess: async () => {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: [usersCacheKey] }),
                queryClient.invalidateQueries({ queryKey: [usersCacheKey, userId] }),
            ]);
            showToast({
                type: 'positive',
                message: 'User successfully synced to zendesk',
            });
        },
    });

    const syncUserToZendesk = () => {
        mutation.mutate(userId);
    };

    return { syncUserToZendesk };
};
