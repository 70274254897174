export const assignmentErrorI18n = {
    'api-error.invalid-assignment-status.title': 'Invalid assignment status',
    'api-error.invalid-assignment-status.message': 'Cannot perform this operation',
    'api-error.invalid-assignment-not-a-loaner.title':
        'Cannot convert assignment to replacement if not a loaner',
    'api-error.invalid-assignment-not-a-loaner.message': 'Cannot perform this operation',
    'api-error.invalid-assignment-without-item.title':
        'Cannot convert assignment to replacement if no item is assigned',
    'api-error.invalid-assignment-without-item.message': 'Cannot perform this operation',
    'api-error.assignment-is-consumable.title': 'Assignment is consumable',
    'api-error.assignment-is-consumable.message': 'Cannot perform this operation',
    'api-error.assignment-is-a-loaner.title': 'Assignment is a loaner',
    'api-error.assignment-is-a-loaner.message': 'Cannot perform this operation',
    'api-error.assignment-is-from-partner-organization.title': 'Assignment is from partner organization',
    'api-error.assignment-is-from-partner-organization.message': 'Cannot perform this operation',
    'api-error.assignment-with-organization-owned-item.title':
        'Assignment has organization owned item attached',
    'api-error.assignment-with-organization-owned-item.message': 'Cannot perform this operation',
    'api-error.assignment-with-management-contract.title': 'Assignment has management contract attached',
    'api-error.assignment-with-management-contract.message': 'Cannot perform this operation',
    'api-error.assignment-has-no-item.title': 'Assignment has no assigned item',
    'api-error.assignment-has-no-item.message': 'Cannot perform this operation',
    'api-error.item-does-not-match-assigned-item.title': 'Item does not match assigned item',
    'api-error.item-does-not-match-assigned-item.message': 'Cannot perform this operation',
    'api-error.assignment-item-is-scanned.title': 'Assignment item is scanned',
    'api-error.assignment-item-is-scanned.message': 'Cannot perform this operation',
    'api-error.assignment-is-not-a-redistribution.title': 'Assignment is not a redistribution',
    'api-error.assignment-is-not-a-redistribution.message': 'Cannot perform this operation',
    'api-error.assignment-item-is-not-scanned.title': 'Assignment item is not scanned',
    'api-error.assignment-item-is-not-scanned.message': 'Cannot perform this operation',
    'api-error.assignment-has-been-shipped.title': 'Assignment has been shipped',
    'api-error.assignment-has-been-shipped.message': 'Cannot perform this operation',
    'api-error.assignment-is-not-final.title': 'Assignment is not final',
    'api-error.assignment-is-not-final.message': 'Cannot perform this operation',
    'api-error.assignment-not-found.title': 'Assignment not found',
    'api-error.assignment-not-found.message': 'Cannot perform this operation',
    'api-error.missing-item.title': 'Missing item',
    'api-error.missing-item.message': 'Cannot perform this operation',
    'api-error.assignment-is-substituted.title': 'Assignment is being replaced',
    'api-error.assignment-is-substituted.message': 'Cannot perform this operation',
    'api-error.contract-and-assignment-organization-mismatch.title':
        'Contract and assignment belong to different organizations',
    'api-error.contract-and-assignment-organization-mismatch.message': 'Cannot perform this operation',
    'api-error.contract-is-already-being-reused.title': 'Contract is already being reused',
    'api-error.contract-is-already-being-reused.message': 'Cannot perform this operation',
    'api-error.contract-inactive.title': 'Rental agreement is not active',
    'api-error.contract-inactive.message': 'Cannot perform this operation',
    'api-error.item-belongs-to-other-org.title': 'Item belongs to other organization',
    'api-error.item-belongs-to-other-org.message': 'Cannot perform this operation',
    'api-error.item-cannot-be-used-as-loaner.title': 'Item cannot be used as loaner',
    'api-error.item-cannot-be-used-as-loaner.message': 'Cannot perform this operation',
    'api-error.item-not-redistributable.title': 'Item is not redistributable',
    'api-error.item-not-redistributable.message': 'Cannot perform this operation',
    'api-error.item-not-usable-in-store-and-reuse.title': 'Item is not reusable',
    'api-error.item-not-usable-in-store-and-reuse.message': 'Cannot perform this operation',
    'api-error.item-contract-has-pending-assignments.title': 'Item contract has pending assignments',
    'api-error.item-contract-has-pending-assignments.message': 'Cannot perform this operation',
    'api-error.assignment-is-loaner.title': 'Item is a loaner assignment',
    'api-error.assignment-is-loaner.message': 'Cannot perform this operation',
    'api-error.assignment-without-contract.title': 'Assignment has no contract attached',
    'api-error.assignment-without-contract.message': 'Cannot perform this operation',
    'api-error.assignment-is-disposed.title': 'Assignment is disposed',
    'api-error.assignment-is-disposed.message': 'Cannot perform this operation',
    'api-error.admin-dispose-customer-owned-asset.title': 'Hofy cannot dispose customer owned assets',
    'api-error.admin-dispose-customer-owned-asset.message': 'Cannot perform this operation',
    'api-error.assignment-is-unconverted-redistribution.title': 'Assignment is unconverted redistribution',
    'api-error.assignment-is-unconverted-redistribution.message': 'Cannot perform this operation',
    'api-error.invalid-item-location.title': 'Invalid item location',
    'api-error.invalid-item-location.message': 'Cannot perform this operation',
    'api-error.assignment-contract-not-pending.title': 'Assignment contract is not pending',
    'api-error.assignment-contract-not-pending.message': 'Cannot perform this operation',
    'api-error.assignment-substitution-missing.title': 'Assignment substitution original request is missing',
    'api-error.assignment-substitution-missing.message': 'Cannot perform this operation',
    'api-error.assignment-status.title': 'Invalid assignment status',
    'api-error.assignment-status.message': 'Cannot perform this operation',
    'api-error.invalid-store-and-reuse-payload.title': 'Invalid payload for store and reuse collection',
    'api-error.invalid-store-and-reuse-payload.message': 'Cannot perform this operation',
    'api-error.customer-uploaded-item-collection-is-not-store-and-reuse.title':
        'Customer uploaded item collection is not store and reuse error',
    'api-error.customer-uploaded-item-collection-is-not-store-and-reuse.message':
        'Cannot perform this operation',
    'api-error.assignment-not-a-store-and-reuse-collection.title':
        'Assignment is not a store and reuse collection',
    'api-error.assignment-not-a-store-and-reuse-collection.message': 'Cannot perform this operation',
    'api-error.assignment-not-a-store-and-reuse-delivery.title':
        'Assignment is not a store and reuse delivery',
    'api-error.assignment-not-a-store-and-reuse-delivery.message': 'Cannot perform this operation',
    'api-error.assignment-with-attached-item-error.title': 'Assignment has item attached',
    'api-error.assignment-with-attached-item-error.message': 'Cannot perform this operation',
    'api-error.rental-contract-status.title': 'Invalid rental contract status',
    'api-error.rental-contract-status.message': 'Cannot perform this operation',
    'api-error.assignment-without-rental-contract.title': 'Assignment has no rental contract attached',
    'api-error.assignment-without-rental-contract.message': 'Cannot perform this operation',
    'api-error.assignment-is-a-redistribution.title': 'Assignment is a redistribution',
    'api-error.assignment-is-a-redistribution.message': 'Cannot perform this operation',
    'api-error.active-rental-cannot-be-replace.title': 'Cannot substitute active rental agreement',
    'api-error.active-rental-cannot-be-replace.message': 'Cannot perform this operation',
    'api-error.assignment-configuration-not-required.title': 'Assignment does not require configuration',
    'api-error.assignment-configuration-not-required.message': 'Cannot perform this operation',
    'api-error.invalid-assignment-configuration-update.title': 'Assignment status is not delivery pending',
    'api-error.invalid-assignment-configuration-update.message': 'Cannot perform this operation',
    'api-error.invalid-assignment-status-for-store-and-reuse.title':
        'Invalid assignment status for store and reuse',
    'api-error.invalid-assignment-status-for-store-and-reuse.message': 'Cannot perform this operation',
    'api-error.item-already-assigned.title': 'Item already attached to active assignment',
    'api-error.item-already-assigned.message': 'Cannot perform this operation',
    'api-error.active-rental-items-cannot-be-transferred-between-tax-zones.title':
        'Active rental items cannot be transferred between tax zones',
    'api-error.active-rental-items-cannot-be-transferred-between-tax-zones.message':
        'Cannot perform this operation',
    'api-error.assignment-not-with-user.title': 'Assignment not with user',
    'api-error.assignment-not-with-user.message': 'Cannot perform this operation',
    'api-error.consumable-or-loaner-assignments-cannot-be-transferred.title':
        'Consumable or loaner assignments cannot be transferred',
    'api-error.consumable-or-loaner-assignments-cannot-be-transferred.message':
        'Cannot perform this operation',
    'api-error.invalid-target-user.title': 'Invalid target user',
    'api-error.invalid-target-user.message': 'Cannot perform this operation',
    'api-error.users-do-not-belong-to-same-organization.title': 'Users do not belong to same organization',
    'api-error.users-do-not-belong-to-same-organization.message': 'Cannot perform this operation',
    'api-error.assignment-has-dropship-shipment.title':
        'Cannot report shipment issue for dropship deliveries',
    'api-error.assignment-has-dropship-shipment.message':
        'Please manually cancel the shipment and resubmit while being in contact with the supplier',
    'api-error.assignment-status-not-pending.title':
        'Assignment status should be delivery pending or collection pending',
    'api-error.assignment-status-not-pending.message': 'Cannot perform this operation',
    'api-error.item-still-being-inspected.title': 'Item still being inspected',
    'api-error.item-still-being-inspected.message': 'Cannot perform this operation',
    'api-error.item-has-damaged-parts.title': 'Item has damaged parts',
    'api-error.item-has-damaged-parts.message': 'Cannot perform this operation',
    'api-error.item-has-missing-parts.title': 'Item has missing parts',
    'api-error.item-has-missing-parts.message': 'Cannot perform this operation',
    'api-error.device-is-locked.title': 'Device is locked',
    'api-error.device-is-locked.message': 'Cannot perform this operation',
    'api-error.contract-store-and-reuse-status-invalid-variant.title': 'Invalid variant to reuse',
    'api-error.contract-store-and-reuse-status-invalid-variant.message': 'Cannot perform this operation',
    'api-error.assignment-is-not-a-rental.title': 'Assignment is not a rental',
    'api-error.assignment-is-not-a-rental.message': 'Cannot perform this operation',
    'api-error.assignment-without-item.title': 'Assignment without item',
    'api-error.assignment-without-item.message': 'Cannot perform this operation',
    'api-error.assignment-contract-has-more-active-assignments.title':
        'Assignment contract has more active assignments',
    'api-error.assignment-contract-has-more-active-assignments.message': 'Cannot perform this operation',
    'api-error.missing-asset-value-for-token.title': 'Missing asset value for token',
    'api-error.missing-asset-value-for-token.message': 'Cannot perform this operation',
    'api-error.missing-fee-for-bill.title': 'Missing fee for bill',
    'api-error.missing-fee-for-bill.message': 'Cannot perform this operation',
    'api-error.missing-invoice-and-token-for-bill.title': 'Missing invoice or token for bill',
    'api-error.missing-invoice-and-token-for-bill.message': 'Cannot perform this operation',
    'api-error.missing-fulfillment-entity-for-bill.title': 'Missing fulfillment entity',
    'api-error.missing-fulfillment-entity-for-bill.message': 'Cannot perform this operation',
    'api-error.cannot-bill-customer-owned-without-location.title':
        'Cannot bill customer owned without location',
    'api-error.cannot-bill-customer-owned-without-location.message': 'Cannot perform this operation',
    'api-error.cannot-bill-hofy-owned-without-contract.title': 'Cannot bill hofy owned without contract',
    'api-error.cannot-bill-hofy-owned-without-contract.message': 'Cannot perform this operation',
    'api-error.missing-contract-for-bill.title': 'Missing contract for bill',
    'api-error.missing-contract-for-bill.message': 'Cannot perform this operation',
    'api-error.fee-cannot-be-negative.title': 'Fee cannot be negative',
    'api-error.fee-cannot-be-negative.message': 'Cannot perform this operation',
    'api-error.fee-cannot-be-zero.title': 'Fee cannot be zero',
    'api-error.fee-cannot-be-zero.message': 'Cannot perform this operation',
    'api-error.service-not-eligible.title': 'Service not eligible',
    'api-error.service-not-eligible.message': 'Cannot perform this operation',
    'api-error.loaner-not-eligible-for-contract.title': 'Loaner not eligible for contract',
    'api-error.loaner-not-eligible-for-contract.message': 'Cannot perform this operation',
    'api-error.contract-must-be-rental-for-bill.title': 'Contract must be rental for bill',
    'api-error.contract-must-be-rental-for-bill.message': 'Cannot perform this operation',
    'api-error.rental-contract-not-ready-for-store-and-reuse.title':
        'Rental contract not ready for store and reuse',
    'api-error.rental-contract-not-ready-for-store-and-reuse.message': 'Cannot perform this operation',
};
