import { min } from 'lodash';
import React, { FC, useMemo } from 'react';

import { StockLevelCountsDto } from '@hofy/api-admin';
import { DateTimeString } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Box, Icon, Paragraph3, SvgIcon, Tooltip } from '@hofy/ui';

import { AssignmentPendingLink } from '../../../assignmentsPage/components/AssignmentPendingLink';
import { StockLevelTooltip } from './StockLevelTooltip';

interface StockLevelCellProps {
    sku: string;
    stockLevel: StockLevelCountsDto;
}

export const StockLevelCell: FC<StockLevelCellProps> = ({ stockLevel, sku }) => {
    const color = stockLevel.available < stockLevel.minQuantity ? Color.FoundationNegative : Color.Neutral700;

    const nextDeliveryOn = useMemo(
        () => min(stockLevel.purchaseOrders.map(po => new Date(po.deliveryOn))),
        [stockLevel.purchaseOrders],
    );

    return (
        <Tooltip
            bodySlot={<StockLevelTooltip sku={sku} stockLevel={stockLevel} />}
            maxWidth='auto'
            interactive
            asChild
        >
            <Box>
                <Box row>
                    <Box row marginRight={12}>
                        <Icon color={color} svg={SvgIcon.Home} size={16} marginRight={6} />
                        <Paragraph3 color={color}>{stockLevel.available}</Paragraph3>
                    </Box>
                    <Box row marginRight={12}>
                        <Icon color={color} svg={SvgIcon.Box} size={16} marginRight={6} />
                        <Paragraph3 color={color}>
                            {' '}
                            {stockLevel.openOrders <= 0 ? (
                                stockLevel.openOrders
                            ) : (
                                <AssignmentPendingLink search={sku} children={stockLevel.openOrders} />
                            )}
                        </Paragraph3>
                    </Box>
                </Box>
                {nextDeliveryOn && (
                    <Paragraph3 color={color} row marginTop={6}>
                        <Icon color={color} svg={SvgIcon.Truck} size={20} marginRight={6} />
                        {formatDate(nextDeliveryOn.toISOString() as DateTimeString)}
                    </Paragraph3>
                )}
            </Box>
        </Tooltip>
    );
};
