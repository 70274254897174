import { ColorDye, getEnumValues } from '@hofy/global';

import { ManagementContractStatus } from './ManagementContractStatus';

export enum RentalContractStatus {
    Pending = 'pending',
    Active = 'active',
    Ended = 'ended',
    Cancelled = 'cancelled',
}

export const rentalContractStatusColors: Record<RentalContractStatus, ColorDye> = {
    [RentalContractStatus.Pending]: 'orange',
    [RentalContractStatus.Active]: 'green',
    [RentalContractStatus.Ended]: 'blue',
    [RentalContractStatus.Cancelled]: 'red',
};

export const rentalContractStatusToManagementStatus: Record<RentalContractStatus, ManagementContractStatus> =
    {
        [RentalContractStatus.Pending]: ManagementContractStatus.Pending,
        [RentalContractStatus.Active]: ManagementContractStatus.Active,
        [RentalContractStatus.Ended]: ManagementContractStatus.Ended,
        [RentalContractStatus.Cancelled]: ManagementContractStatus.Cancelled,
    };

export const allRentalContractStatuses = getEnumValues<RentalContractStatus>(RentalContractStatus);
export const nonCanceledRentalContractStatuses = [
    RentalContractStatus.Pending,
    RentalContractStatus.Active,
    RentalContractStatus.Ended,
];

export const activeOrPendingRentalContractStatuses = [
    RentalContractStatus.Pending,
    RentalContractStatus.Active,
];
