import { useQuery } from '@tanstack/react-query';

import { contractService } from '@hofy/api-admin';
import { PaymentSchema } from '@hofy/api-shared';
import { DateString } from '@hofy/global';

export const useValidateEnrollDevicesIntoServicePackage = (
    itemIds: number[],
    activeOn: DateString,
    addonIds: number[],
    paymentSchema: PaymentSchema,
    duration: number,
) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [
            'admin/contract/validate-enrollment',
            itemIds,
            activeOn,
            addonIds,
            duration,
            paymentSchema,
        ],

        queryFn: () =>
            contractService.validateEnrollItemsIntoServicePackage({
                itemIds,
                activeOn,
                addonIds,
                duration,
                paymentSchema,
            }),
    });

    return {
        data: data || [],
        isLoading,
        isError,
    };
};
