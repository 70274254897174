import React, { FC, useRef, useState } from 'react';

import { useEmailLogQuery } from '@hofy/api-admin';
import { ComponentLoader, Slideout, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { Box, ErrorStatePlaceholder } from '@hofy/ui';

import { CancelButton } from '../../design/button/CancelButton';

interface EmailPreviewSlideoutProps {
    emailId: number;
    onClose(): void;
}

export const EmailPreviewSlideout: FC<EmailPreviewSlideoutProps> = ({ emailId, onClose }) => {
    const { email, isLoading } = useEmailLogQuery(emailId);
    const [height, setHeight] = useState<string>('99%');
    const ref = useRef<HTMLIFrameElement>(null);

    const handleIframeLoad = () => {
        const iframeItem = ref.current;
        if (iframeItem?.contentDocument) {
            setHeight(iframeItem.contentDocument.body.scrollHeight + 20 + 'px');
        }
    };

    const renderContent = () => {
        if (isLoading) {
            return <ComponentLoader />;
        }

        if (!email) {
            return <ErrorStatePlaceholder />;
        }

        return (
            <iframe
                ref={ref}
                style={{
                    pointerEvents: 'none',
                }}
                srcDoc={email.messageBody}
                height={height}
                width='100%'
                onLoad={handleIframeLoad}
            />
        );
    };
    return (
        <Slideout width={600} onClose={onClose}>
            <SlideoutHeader title='Email preview' />
            <Box overflow='auto' flex={1} borderBottom relative>
                {renderContent()}
            </Box>
            <SlideoutFooter>
                <CancelButton onClick={onClose} />
            </SlideoutFooter>
        </Slideout>
    );
};
