import React, { FC } from 'react';

import { OrganizationDetailsDto, useOrganizationEmailLogsQuery } from '@hofy/api-admin';

import { EmailsTable } from '../../../components/domain/emailLogs/EmailsTable';

interface EmailsTabProps {
    organization: OrganizationDetailsDto;
    onOpenEmail(emailId: number): void;
}

export const OrganizationEmailsTab: FC<EmailsTabProps> = ({ organization, onOpenEmail }) => {
    const { emails, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage } =
        useOrganizationEmailLogsQuery(organization.id);

    return (
        <EmailsTable
            emails={emails}
            infinityScroll={{
                hasMore: hasNextPage,
                isLoading,
                isLoadingMore: isFetchingNextPage,
                loadMore: fetchNextPage,
            }}
            onOpenEmail={onOpenEmail}
            isError={isError}
        />
    );
};
