import React, { FC } from 'react';

import { Role } from '@hofy/api-shared';
import { FormDropdown } from '@hofy/common';
import { TestKeyAware } from '@hofy/ui';

import { useOrganizationUserByRoleOptions } from '../../../store/organizations/useOrganizationUserByRoleOptions';

interface OrganizationUsersListByRoleDropdownProps extends TestKeyAware {
    value: number | null;
    label: string;
    role: Role;
    onChange(o: number | null): void;
    isRequired?: boolean;
    isError?: boolean | string;
}

export const OrganizationUsersListByRoleDropdown: FC<OrganizationUsersListByRoleDropdownProps> = ({
    value,
    label,
    role,
    onChange,
    isRequired,
    isError,
}) => {
    const { options, ids } = useOrganizationUserByRoleOptions(role);
    const toLabel = (v: number) => options[v];

    return (
        <FormDropdown
            items={ids}
            labelFormatter={id => (id !== null ? options[id] : '')}
            onChange={i => onChange(i || null)}
            value={value}
            label={label}
            toSearchLabel={toLabel}
            searchable
            isRequired={isRequired}
            isError={isError}
        />
    );
};
