import { SupplierRefDto } from '@hofy/api-shared';
import { PageRequest, PageResponse, stringifyUrl } from '@hofy/global';
import { downloadFileFromResponse } from '@hofy/helpers';
import { restClient } from '@hofy/rest';

import { AdminSuppliersFilters } from './types/AdminSuppliersFilters';
import { SupplierDetailDto } from './types/SupplierDetailDto';
import { SupplierListItemDto } from './types/SupplierListItemDto';
import { SupplierContactPayload, SupplierPayload } from './types/SupplierPayload';

class SuppliersService {
    public syncSuppliersToNetsuite = async (): Promise<void> => {
        return restClient.post('/api/admin/suppliers/sync-to-netsuite');
    };

    public listSuppliers = async (
        page: PageRequest,
        filters?: AdminSuppliersFilters,
    ): Promise<PageResponse<SupplierListItemDto>> => {
        return restClient.getJson<PageResponse<SupplierListItemDto>>(
            stringifyUrl({
                url: '/api/admin/suppliers',
                query: {
                    page: page.page,
                    pageSize: page.pageSize,
                    search: filters?.search,
                    type: filters?.types,
                    status: filters?.statuses,
                    subsidiary: filters?.subsidiaries,
                    country: filters?.country,
                    netsuiteStatus: filters?.netsuiteStatuses,
                },
            }),
        );
    };

    public getSuppliersRefs = async (): Promise<SupplierRefDto[]> => {
        return await restClient.getJson<SupplierRefDto[]>(`/api/admin/suppliers/refs`);
    };

    public downloadListSuppliersReport = async (filters: AdminSuppliersFilters): Promise<void> => {
        const response = await restClient.downloadFile(
            stringifyUrl({
                url: '/api/admin/suppliers/report',
                query: {
                    search: filters.search,
                    status: filters.statuses,
                    type: filters.types,
                    subsidiary: filters.subsidiaries,
                    country: filters.country,
                    netsuiteStatus: filters.netsuiteStatuses,
                },
            }),
        );
        downloadFileFromResponse(response);
    };

    public getSupplier = async (supplierId: number): Promise<SupplierDetailDto> => {
        return restClient.getJson<SupplierDetailDto>(`/api/admin/suppliers/${supplierId}`);
    };

    public createSupplier = async (payload: SupplierPayload): Promise<number> => {
        const response = await restClient.postJson<{
            id: number;
        }>('/api/admin/suppliers', payload);
        return response.id;
    };

    public updateSupplier = async (supplierId: number, payload: SupplierPayload): Promise<void> => {
        return restClient.put(`/api/admin/suppliers/${supplierId}`, payload);
    };

    public addContact = async (supplierId: number, payload: SupplierContactPayload): Promise<number> => {
        const response = await restClient.postJson<{
            id: number;
        }>(`/api/admin/suppliers/${supplierId}/contacts`, payload);
        return response.id;
    };

    public updateContact = async (
        supplierId: number,
        contactId: number,
        payload: SupplierContactPayload,
    ): Promise<void> => {
        return restClient.put(`/api/admin/suppliers/${supplierId}/contacts/${contactId}`, payload);
    };

    public deleteContact = async (supplierId: number, contactId: number): Promise<Response> => {
        return restClient.delete(`/api/admin/suppliers/${supplierId}/contacts/${contactId}`).then();
    };
}

export const suppliersService = new SuppliersService();
