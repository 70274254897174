import React, { FC } from 'react';
import styled from 'styled-components';

import { OrganizationDetailsDto, useItPoliciesQuery } from '@hofy/api-admin';
import { allTeamColorsOptions, TeamColor, teamColorMapping, useTrTeamColor } from '@hofy/api-shared';
import { getColorFromColorDye } from '@hofy/theme';
import {
    AsyncButton,
    Box,
    FormContainer,
    FormInput,
    FormModal,
    FormSelect,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph3,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useCreateItPolicy } from '../../../store/teams/useCreateItPolicy';

interface CreateItPolicyModalProps {
    onClose?(): void;
    organization: OrganizationDetailsDto;
}

export const CreateItPolicyModal: FC<CreateItPolicyModalProps> = ({ onClose = () => {}, organization }) => {
    const { itPolicies } = useItPoliciesQuery(organization.id);
    const { form, isLoading, isError } = useCreateItPolicy(organization.id, itPolicies, onClose);

    return (
        <FormModal
            width={550}
            onSubmit={form.submit}
            onClose={form.discard}
            isLoading={isLoading}
            isError={isError}
        >
            <ModalHeader title='Create new team' borderBottom />
            <ModalContent>
                <FormContainer>
                    <Paragraph3 paddingBottom={10}>
                        Create a team and add team members to allow them to create orders of approved
                        products.
                    </Paragraph3>
                    <Box row alignItems='flex-start'>
                        <Box flex={1} marginRight={10}>
                            <FormInput
                                label='Team name'
                                api={form.fields.name}
                                placeholder='Enter team name'
                                isRequired
                            />
                        </Box>
                        <FormSelect
                            label='Colour'
                            options={allTeamColorsOptions}
                            api={form.fields.color}
                            toText={color => color}
                            toLabel={color => <ColorLabel color={color} />}
                            width={150}
                        />
                    </Box>
                </FormContainer>
            </ModalContent>
            <ModalFooter borderTop>
                <CancelButton onClick={form.discard} />
                <AsyncButton label='Create' />
            </ModalFooter>
        </FormModal>
    );
};

interface ColorLabelProps {
    color: TeamColor;
}

const ColorLabel: FC<ColorLabelProps> = ({ color }) => {
    const trColorOption = useTrTeamColor();

    return (
        <Box row>
            <ColorBox bg={getColorFromColorDye(teamColorMapping[color], 'main')} />
            <Paragraph3>{trColorOption(color)}</Paragraph3>
        </Box>
    );
};

const ColorBox = styled(Box)`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
`;
