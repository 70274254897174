import { EmailType } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { GenerateSignupLinkDto } from './types/GenerateSignupLinkDto';

export interface TokenPair {
    access_token: string;
    refresh_token: string;
    token_type: 'bearer';
}

class AuthService {
    public generateSignupLink = async (
        emailType: EmailType,
        userId: number,
    ): Promise<GenerateSignupLinkDto> =>
        await restClient.postJson(`/api/admin/auth/users/${userId}/signup-link`, {
            emailType,
        });

    public signInToOrganization = (organizationId: UUID): Promise<TokenPair> => {
        return restClient.postJson<TokenPair>(`/api/admin/auth/sign-in-to-organization/${organizationId}`);
    };
}

export const authService = new AuthService();
