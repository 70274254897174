import { useMutation, useQueryClient } from '@tanstack/react-query';

import { suppliersCacheKey, suppliersService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useDeleteSupplierContact = (supplierId: number) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const deleteContact = useMutation({
        mutationFn: (contactId: number) => suppliersService.deleteContact(supplierId, contactId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [suppliersCacheKey] });
            showToast({
                type: 'positive',
                message: 'Supplier contact deleted',
            });
        },
    });

    return {
        deleteContact: deleteContact.mutate,
    };
};
