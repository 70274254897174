import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty, isNull, noop } from 'lodash';

import { assignmentsCacheKey, itemCacheKey, repairsCacheKey, repairsService } from '@hofy/api-admin';
import { DateString } from '@hofy/global';
import { errorMap, nowISODate } from '@hofy/helpers';
import { useForm, useToast } from '@hofy/ui';

interface CreateRepairFormData {
    itemId: number | null;
    noteText: string | null;
    createdOn: DateString;
    assignedUserId: number | null;
}

interface CreateRepairProps {
    itemId?: number;
    onSuccess?(): void;
}

export const useCreateRepair = ({ itemId, onSuccess = noop }: CreateRepairProps) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: repairsService.createRepair,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [repairsCacheKey],
            });
            queryClient.invalidateQueries({
                queryKey: [assignmentsCacheKey],
            });
            queryClient.invalidateQueries({
                queryKey: [itemCacheKey],
            });
            showToast({
                type: 'positive',
                message: 'Repair case created successfully',
            });
            onSuccess();
        },
    });

    const form = useForm<CreateRepairFormData>({
        initial: {
            itemId: itemId ?? null,
            noteText: null,
            createdOn: nowISODate(),
            assignedUserId: null,
        },
        onSubmit: v => {
            mutation.mutate({
                itemId: v.itemId as number,
                note: v.noteText as string,
                createdOn: v.createdOn,
                assignedUserId: v.assignedUserId,
            });
        },
        validate: ({ itemId, noteText, createdOn }) => ({
            itemId: errorMap([isNull(itemId), 'Item id is required']),
            noteText: errorMap([isEmpty(noteText), 'Note text is required']),
            createdOn: errorMap([isEmpty(createdOn), 'Repair start date is required']),
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
