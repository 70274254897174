import { xor } from 'lodash';
import React, { FC, useState } from 'react';

import { formatAdminName, UserRefDto } from '@hofy/api-shared';
import { useSearch } from '@hofy/common';
import { SvgIcon } from '@hofy/ui';

import { SearchableBlockFilter } from '../../design/blockFilters/SearchableBlockFilter';

interface UsersBlockFilterProps {
    title?: string;
    users: UserRefDto[];
    selected: number[];
    onChange(userId: number[]): void;
}

export const UsersBlockFilter: FC<UsersBlockFilterProps> = ({
    title = 'Assigned user',
    users,
    selected,
    onChange,
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const { results } = useSearch(users, admin => formatAdminName(admin), searchQuery);

    return (
        <SearchableBlockFilter
            title={title}
            icon={SvgIcon.User}
            items={results}
            isSelected={user => selected.includes(user.idDeprecated)}
            onFilterClick={user => onChange(xor(selected, [user.idDeprecated]))}
            renderItem={country => formatAdminName(country)}
            searchPlaceholder='Search user'
            search={searchQuery}
            onSearch={setSearchQuery}
        />
    );
};
