import React, { FC } from 'react';

import { AssignmentDto, dateForAssignmentStatus } from '@hofy/api-shared';
import { Box } from '@hofy/ui';

import { AssignmentTypeBadge } from '../../../components/domain/assignments/badge/AssignmentTypeBadge';
import { InPersonTransferBadge } from '../../../components/domain/assignments/badge/InPersonTransferBadge';
import { StoreAndReuseBadge } from '../../../components/domain/assignments/badge/StoreAndReuseBadge';
import { AssignmentTypeChip } from './AssignmentTypeChip';
import { CollectionNotBeforeChip } from './CollectionNotBeforeChip';

interface AssignmentTypeCellProps {
    assignment: AssignmentDto;
}

export const AssignmentTypeCell: FC<AssignmentTypeCellProps> = ({ assignment }) => {
    return (
        <Box width={155} column alignItems='flex-start' flex='auto' gap={12}>
            <AssignmentTypeBadge type={assignment.type} />
            {assignment.isStoreAndReuseDelivery && (
                <StoreAndReuseBadge type='delivery' country={null} isLocalRegion={null} />
            )}
            {assignment.isStoreAndReuseCollection && (
                <StoreAndReuseBadge type='collection' country={null} isLocalRegion={null} />
            )}
            <AssignmentTypeChip
                status={assignment.status}
                exception={assignment.exception}
                collectionReason={assignment.collectionReason}
                date={dateForAssignmentStatus(assignment, assignment.status)}
            />
            {assignment.collectionNotBefore && (
                <CollectionNotBeforeChip date={assignment.collectionNotBefore} />
            )}
            <InPersonTransferBadge
                assignmentStatus={assignment.status}
                exception={assignment.exception}
                assignmentCollectionShipmentId={assignment.collectionShipmentId}
                assignmentDeliveryShipmentId={assignment.deliveryShipmentId}
            />
        </Box>
    );
};
