import { stringifyUrl } from 'query-string';

import { PageRequest, PageResponse } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { MessageDetailsDto, MessageDto } from './types/MessageDto';

class MessagingService {
    public listUserMessages = async (
        userId: number,
        page: PageRequest,
    ): Promise<PageResponse<MessageDto>> => {
        return restClient.getJson<PageResponse<MessageDto>>(
            stringifyUrl({
                url: `/api/admin/messages/users/${userId}`,
                query: {
                    page: page.page,
                    pageSize: page.pageSize,
                },
            }),
        );
    };

    public getMessage = async (messageId: number): Promise<MessageDetailsDto> => {
        return restClient.getJson<MessageDetailsDto>(`/api/admin/messages/${messageId}`);
    };
}

export const messagingService = new MessagingService();
