import { DateString, stringifyUrl } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { IssueDetailsDto } from './types/IssueDetailsDto';
import { IssueDto } from './types/IssueDto';
import { IssueFilter } from './types/IssueFilter';

interface IssuesResponse {
    issues: IssueDto[];
}

export interface UpdateIssuePayload {
    resolvedOn: DateString | null;
}

class IssueService {
    public listIssues = async (tabFilter: IssueFilter): Promise<IssueDto[]> => {
        const response = await restClient.getJson<IssuesResponse>(
            stringifyUrl({
                url: `/api/admin/issues/list?filter=${tabFilter}`,
            }),
        );
        return response.issues;
    };

    public getIssue = (id: number): Promise<IssueDetailsDto> => {
        return restClient.getJson<IssueDetailsDto>(`/api/admin/issues/${id}`);
    };

    public updateIssue = (id: number, payload: UpdateIssuePayload) => {
        return restClient.put(`/api/admin/issues/${id}`, payload);
    };
}

export const issueService = new IssueService();
