import React, { FC } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { useGoBack } from '@hofy/hooks';
import { EnumRoute, IntRoute } from '@hofy/router';

import { CreateRepairForItemSlideoutRouter } from '../repairsPage/slideouts/createRepair/CreateRepairForItemSlideoutRouter';
import { AdminAssignmentSlideoutTabs } from './AdminAssignmentSlideoutTabs';
import { AssignmentSlideoutContent } from './AssignmentSlideoutContent';
import { ConfigureProductSlideout } from './configureProductSlideout/ConfigureProductSlideout';
import { DataErasureSlideout } from './dataErasureSlideout/DataErasureSlideout';
import { PickCustomProductSlideout } from './pickCustomProductSlideout/PickCustomProductSlideout';
import { SubstituteProductSlideout } from './substituteProductSlideout/SubstituteProductSlideout';

interface OrderSlideoutRouterProps {
    base: string;
    shipmentsBase: string;
    onCloseBase: string;
}

export const AssignmentSlideoutRouter: FC<OrderSlideoutRouterProps> = ({
    base,
    shipmentsBase,
    onCloseBase,
}) => {
    const history = useHistory();

    const handleTabChange = (orderId: number, tab: AdminAssignmentSlideoutTabs) => {
        history.replace(`${base}/${orderId}/${tab}${history.location.search}`);
    };

    const handleSubstituteProduct = (orderId: number) => {
        history.push(`${base}/${orderId}/details/substitute${history.location.search}`);
    };

    const handleConfigureProduct = (orderId: number) => {
        history.push(`${base}/${orderId}/details/configure${history.location.search}`);
    };

    const handleEraseDevice = (assignmentId: number) => {
        history.push(`${base}/${assignmentId}/details/data-erasure${history.location.search}`);
    };

    const handlePickCustomProduct = (orderId: number) => {
        history.push(`${base}/${orderId}/details/custom-product${history.location.search}`);
    };

    const handleShipmentClick = (shipmentId: number) => {
        history.push(`${shipmentsBase}/${shipmentId}/details${history.location.search}`);
    };

    const handleCreateRepair = (repairId: number) => {
        history.push(`${base}/create-repair/${repairId}${history.location.search}`);
    };

    return (
        <>
            <OrderSlideoutRoute path={`${base}/:orderId(\\d+)`}>
                {({ orderId }) => (
                    <Switch>
                        <OrderSlideoutTabRoute path={`${base}/:orderId(\\d+)/:activeTab`}>
                            {({ activeTab }) => (
                                <OrderSlideout
                                    base={base}
                                    onCloseBase={onCloseBase}
                                    orderId={orderId}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    onSubstituteProduct={() => handleSubstituteProduct(orderId)}
                                    onConfigureProduct={() => handleConfigureProduct(orderId)}
                                    onEraseDevice={() => handleEraseDevice(orderId)}
                                    onPickCustomProduct={() => handlePickCustomProduct(orderId)}
                                    onShipmentClick={handleShipmentClick}
                                    onCreateRepair={handleCreateRepair}
                                />
                            )}
                        </OrderSlideoutTabRoute>
                        <Redirect
                            to={`${base}/${orderId}/${AdminAssignmentSlideoutTabs.Details}${history.location.search}`}
                        />
                    </Switch>
                )}
            </OrderSlideoutRoute>
            <CreateRepairForItemSlideoutRouter
                base={`${base}/create-repair`}
                onClose={() => history.push(onCloseBase)}
            />
        </>
    );
};

interface OrderSlideoutProps {
    base: string;
    onCloseBase: string;
    orderId: number;
    activeTab: AdminAssignmentSlideoutTabs;
    onSubstituteProduct(): void;
    onConfigureProduct(): void;
    onEraseDevice(): void;
    onPickCustomProduct(): void;
    handleTabChange(orderId: number, tab: AdminAssignmentSlideoutTabs): void;
    onShipmentClick(id: number): void;
    onCreateRepair(repairId: number): void;
}

const OrderSlideout: FC<OrderSlideoutProps> = ({
    base,
    onCloseBase,
    onSubstituteProduct,
    onConfigureProduct,
    onEraseDevice,
    onPickCustomProduct,
    orderId,
    activeTab,
    handleTabChange,
    onShipmentClick,
    onCreateRepair,
}) => {
    const history = useHistory();
    const { goBack } = useGoBack();

    const historyPush = (path: string) => {
        history.push(`${path}${history.location.search}`);
    };

    const handleGoBack = () => {
        goBack(`${base}/${orderId}/${activeTab}`);
    };

    return (
        <>
            <AssignmentSlideoutContent
                assignmentId={orderId}
                activeTab={activeTab}
                onTabChange={tabName => handleTabChange(orderId, tabName)}
                onClose={() => historyPush(onCloseBase)}
                onSubstituteProduct={onSubstituteProduct}
                onConfigureProduct={onConfigureProduct}
                onEraseDevice={onEraseDevice}
                onPickCustomProduct={onPickCustomProduct}
                onShipmentClick={onShipmentClick}
                onCreateRepair={onCreateRepair}
            />
            <Route path={`${base}/:orderId(\\d+)/:tabName/substitute`} exact>
                <SubstituteProductSlideout
                    basePath={`${base}/${orderId}/${activeTab}`}
                    orderId={orderId}
                    onClose={handleGoBack}
                />
            </Route>
            <Route path={`${base}/:orderId(\\d+)/:tabName/configure`} exact>
                <ConfigureProductSlideout
                    basePath={`${base}/${orderId}/${activeTab}`}
                    orderId={orderId}
                    onClose={handleGoBack}
                />
            </Route>
            <Route path={`${base}/:orderId(\\d+)/:tabName/data-erasure`} exact>
                <DataErasureSlideout assignmentId={orderId} onClose={handleGoBack} />
            </Route>
            <Route path={`${base}/:orderId(\\d+)/:tabName/custom-product`} exact>
                <PickCustomProductSlideout onClose={handleGoBack} assignmentId={orderId} />
            </Route>
        </>
    );
};

const OrderSlideoutRoute = IntRoute('orderId', Route);
const OrderSlideoutTabRoute = EnumRoute<AdminAssignmentSlideoutTabs>(
    'activeTab',
    AdminAssignmentSlideoutTabs,
    Route,
);
