import { xor } from 'lodash';
import React, { FC } from 'react';

import { formatAdminName, unassignedUser, UserRefDto } from '@hofy/api-shared';
import { FilterChip } from '@hofy/ui';

interface AssignedUsersFilterChipsProps {
    allUsers: UserRefDto[];
    selectedUserIds: number[];
    onChange(admin: number[]): void;
}

export const AssignedUsersFilterChips: FC<AssignedUsersFilterChipsProps> = ({
    selectedUserIds,
    allUsers,
    onChange,
}) => {
    const activeUsers = allUsers.filter(user => selectedUserIds.includes(user.idDeprecated));
    const isUnassignedSelected = selectedUserIds.includes(unassignedUser);

    const toggleUser = (id: number) => {
        onChange(xor(selectedUserIds, [id]));
    };

    return (
        <>
            {isUnassignedSelected && (
                <FilterChip color='blue' label='Unassigned' onClear={() => toggleUser(unassignedUser)} />
            )}
            {activeUsers.map(admin => (
                <FilterChip
                    label={formatAdminName(admin)}
                    onClear={() => toggleUser(admin.idDeprecated)}
                    color='blue'
                    key={admin.id}
                />
            ))}
        </>
    );
};
