import {
    AddressDto,
    AssignmentCollectionReason,
    AssignmentConfigurationStatus,
    AssignmentException,
    AssignmentStatus,
    AssignmentStatusDatesDto,
    AssignmentType,
    ContractUnionDto,
    NoteDto,
    ProductDto,
    VariantDetailsDto,
} from '@hofy/api-shared';
import { DateString, UUID } from '@hofy/global';

import { DataErasureDetailsDto } from '../../../dataErasure/service/types/DataErasureDetailsDto';
import { StockLevelCountsDto } from '../../../item/service/types/StockLevelsDto';
import { WarehouseDetailsUnionDto } from '../../../warehouse/service/types/WarehouseDetailsDto';
import { AssignmentCollectionRequestItemDto } from './AssignmentCollectionRequestItemDto';
import { AssignmentItemDto } from './AssignmentItemDto';
import { AssignmentProductDetailsDto } from './AssignmentProductDetailsDto';

export interface UserAssignmentDto {
    id: number;
    uuid: UUID;
    publicId: string;
    userId: number;
    status: AssignmentStatus;
    type: AssignmentType;
    statusDates: AssignmentStatusDatesDto;
    configurationStatus: AssignmentConfigurationStatus | null;

    dataErasure: DataErasureDetailsDto | null;

    exception: AssignmentException | null;
    collectionReason: AssignmentCollectionReason | null;

    collectionNotBefore: DateString | null;
    latestDeliveryOn: DateString | null;

    product: ProductDto;
    customProduct: AssignmentProductDetailsDto;
    variant: VariantDetailsDto;
    contract: ContractUnionDto | null;
    requestedVariantId: UUID | null;
    item: AssignmentItemDto | null;
    assignedItemNeedsScanning: boolean;

    isConsumable: boolean;
    isOnHold: boolean;
    isHistorical: boolean;

    isStoreAndReuseDelivery: boolean;
    isStoreAndReuseCollection: boolean;

    storeAndReuseProductAvailable: boolean;
    storeAndReuseCategoryAvailable: boolean;

    stockLevels: StockLevelCountsDto | null;

    collectionRequestItem: AssignmentCollectionRequestItemDto | null;
    deliveryAddress: AddressDto;
    expressDeliveryRequested: boolean;

    collectionShipmentId: number | null;
    deliveryShipmentId: number | null;

    salesOrderId: number | null;

    collectionToOrganizationWarehouse: WarehouseDetailsUnionDto | null;

    notes: NoteDto[];
}

export const isNotLoanerOrConsumable = (assignment: UserAssignmentDto) => {
    return assignment.type !== AssignmentType.Loaner && !assignment.isConsumable;
};
