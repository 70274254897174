import React, { FC, useCallback, useState } from 'react';

import { UserWithCountryRefDto } from '@hofy/api-shared';
import { Country, redistributableTo, UUID } from '@hofy/global';
import { AsyncButton, Modal, ModalContent, ModalFooter, ModalHeader } from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { LabeledUserSelect } from '../../../components/domain/user/LabeledUserSelect';
import { useCreateAssignment } from '../../../store/users/useCreateAssignment';

interface CreateAssignmentModalProps {
    itemId: number;
    organizationId: UUID;
    redistributableIn: Country;
    onClose(): void;
}

export const CreateAssignmentModal: FC<CreateAssignmentModalProps> = ({
    redistributableIn,
    onClose,
    organizationId,
    itemId,
}) => {
    const [userId, setUserId] = useState<number>();
    const { createAssignment, isLoading } = useCreateAssignment(userId ?? 0, onClose);

    const userPredicate = useCallback(
        (user: UserWithCountryRefDto) => {
            return user.isOffBoarded === false && redistributableTo(redistributableIn, user.country);
        },
        [redistributableIn],
    );

    return (
        <Modal onClose={onClose} width={450}>
            <ModalHeader title='Create item assignment' />
            <ModalContent>
                <LabeledUserSelect
                    label='To user'
                    organizationId={organizationId}
                    userPredicate={userPredicate}
                    value={userId ?? null}
                    onChange={userId => setUserId(userId ?? undefined)}
                    isRequired
                    nullable
                />
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={onClose} />
                <AsyncButton
                    label='Add assignment'
                    onClick={() =>
                        createAssignment({
                            itemId,
                        })
                    }
                    disabled={!userId}
                    isLoading={isLoading}
                />
            </ModalFooter>
        </Modal>
    );
};
