import { sortBy } from 'lodash';
import React, { FC, useMemo } from 'react';

import { RepairStatusUpdateDto } from '@hofy/api-admin';
import { getRepairStatusLabel } from '@hofy/api-shared';
import { Timeline } from '@hofy/common';
import { formatDate, parseDateTime } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Box, Paragraph3 } from '@hofy/ui';

interface RepairTimelineProps {
    statusUpdates: RepairStatusUpdateDto[];
}

export const RepairTimeline: FC<RepairTimelineProps> = ({ statusUpdates }) => {
    const timelineItems = useMemo(
        () => sortBy([...statusUpdates], item => parseDateTime(item.createdAt!).toUnixInteger()).reverse(),
        [statusUpdates],
    );

    return (
        <Box row marginTop={10}>
            <Timeline
                currentIndex={timelineItems.length - 1}
                items={timelineItems.map(item => {
                    return (
                        <Box key={item.idDeprecated}>
                            <RepairTimelineStatusUpdate statusUpdate={item} />
                        </Box>
                    );
                })}
            />
        </Box>
    );
};

interface RepairTimelineStatusUpdateProps {
    statusUpdate: RepairStatusUpdateDto;
}

const RepairTimelineStatusUpdate: FC<RepairTimelineStatusUpdateProps> = ({ statusUpdate }) => {
    return (
        <>
            <Paragraph3 bold>{getRepairStatusLabel(statusUpdate.status)}</Paragraph3>
            <Paragraph3 color={Color.ContentTertiary}>{formatDate(statusUpdate.updatedOn)}</Paragraph3>
        </>
    );
};
