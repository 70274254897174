import { UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import {
    OrganizationAddonConfigDetailsDto,
    OrganizationAddonConfigDto,
} from './types/OrganizationAddonConfigDto';
import { UpdateOrCreateAddonConfigPayload } from './types/UpdateOrCreateAddonConfigPayload';

interface ListAddonConfigsResponse {
    addonsWithConfig: OrganizationAddonConfigDto[];
}

class OrganizationsAddonConfigService {
    public listOrganizationAddonConfigurations = async (
        orgId: UUID,
    ): Promise<OrganizationAddonConfigDto[]> => {
        const result = await restClient.getJson<ListAddonConfigsResponse>(
            `/api/admin/organizations/${orgId}/addon-configurations`,
        );
        return result.addonsWithConfig;
    };

    public getOrganizationAddonConfiguration = async (
        orgId: UUID,
        addonId: number,
    ): Promise<OrganizationAddonConfigDetailsDto> =>
        await restClient.getJson<OrganizationAddonConfigDetailsDto>(
            `/api/admin/organizations/${orgId}/addon-configurations/${addonId}`,
        );

    public upsertOrganizationAddonConfiguration = async (
        organizationId: UUID,
        addonId: number,
        payload: UpdateOrCreateAddonConfigPayload,
    ) => {
        return restClient.put(
            `/api/admin/organizations/${organizationId}/addon-configurations/${addonId}`,
            payload,
        );
    };
}

export const organizationAddonConfigService = new OrganizationsAddonConfigService();
