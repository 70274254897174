import React, { FC } from 'react';

import { UserDto, useUserEmailLogsQuery } from '@hofy/api-admin';

import { EmailsTable } from '../../../components/domain/emailLogs/EmailsTable';

interface EmailsTabProps {
    user: UserDto;
    onOpenEmail(emailId: number): void;
}

export const UserEmailsTab: FC<EmailsTabProps> = ({ user, onOpenEmail }) => {
    const {
        adminEmails,
        adminEmailsIsLoading,
        adminEmailsIsError,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useUserEmailLogsQuery(user.idDeprecated);

    return (
        <EmailsTable
            emails={adminEmails}
            infinityScroll={{
                hasMore: hasNextPage,
                isLoading: adminEmailsIsLoading,
                isLoadingMore: isFetchingNextPage,
                loadMore: fetchNextPage,
            }}
            onOpenEmail={onOpenEmail}
            isError={adminEmailsIsError}
        />
    );
};
