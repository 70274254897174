import React, { FC } from 'react';

import { AssignmentException, AssignmentStatus } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface InPersonTransferBadgeProps {
    assignmentStatus: AssignmentStatus;
    exception: AssignmentException | null;
    assignmentCollectionShipmentId: number | null;
    assignmentDeliveryShipmentId: number | null;
}

export const InPersonTransferBadge: FC<InPersonTransferBadgeProps> = ({
    assignmentStatus,
    exception,
    assignmentCollectionShipmentId,
    assignmentDeliveryShipmentId,
}) => {
    const isValidCompletionReason = !exception;
    const isDeliveredWithoutShipment =
        assignmentStatus === AssignmentStatus.WithUser && !assignmentDeliveryShipmentId;
    const isCollectedWithoutShipment =
        assignmentStatus === AssignmentStatus.Collected && !assignmentCollectionShipmentId;

    if (isDeliveredWithoutShipment) {
        return <Badge label='In-person delivery' color='green' />;
    }

    if (isValidCompletionReason && isCollectedWithoutShipment) {
        return <Badge label='In-person collection' color='aquamarine' />;
    }

    return null;
};
