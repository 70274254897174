import { Permission } from '@hofy/api-shared';

export enum AdminNavLink {
    Assignments = '/assignments',
    Products = '/products',
    Organizations = '/organizations',
    Shipments = '/shipments',
    Inspections = '/inspections',
    Issues = '/issues',
    Inventory = '/inventory',
    Items = '/items',
    Repairs = '/repairs',
    Users = '/users',
    Orders = '/orders',
    Contracts = '/contracts',
    AuditLogs = '/audit-logs',
    Settings = '/settings',
    Invoicing = '/invoicing',
    Accounting = '/accounting',
    Notifications = '/notifications',
    ApiKeys = '/settings/api-keys',
    ExchangeRates = '/settings/exchange-rates',
    BetaFeatures = '/settings/beta-features',
    Jobs = '/jobs',
}

// The order here is relevant as the first will be the default landing page
export const adminLinkPermissions: Record<AdminNavLink, Permission[]> = {
    [AdminNavLink.Assignments]: [Permission.AdminAssignmentsView],
    [AdminNavLink.Products]: [Permission.AdminProductsView],
    [AdminNavLink.Organizations]: [Permission.AdminOrganizationView],
    [AdminNavLink.Shipments]: [Permission.AdminShipmentsView],
    [AdminNavLink.Inspections]: [Permission.AdminInspectionsView],
    [AdminNavLink.Issues]: [Permission.AdminIssuesView],
    [AdminNavLink.Inventory]: [
        Permission.AdminStockView,
        Permission.AdminPurchaseOrdersView,
        Permission.AdminSuppliersView,
    ],
    [AdminNavLink.Items]: [Permission.AdminItemsView],
    [AdminNavLink.Repairs]: [Permission.AdminRepairsView],
    [AdminNavLink.Users]: [Permission.AdminUsersView],
    [AdminNavLink.Orders]: [Permission.AdminOrdersView],
    [AdminNavLink.Contracts]: [Permission.AdminContractsView],
    [AdminNavLink.AuditLogs]: [Permission.AdminAuditLogsView],
    [AdminNavLink.Settings]: [Permission.AdminTermAndConditionView],
    [AdminNavLink.ExchangeRates]: [Permission.AdminExchangeRateView],
    [AdminNavLink.Invoicing]: [Permission.AdminInvoicesView],
    [AdminNavLink.Accounting]: [Permission.AdminAccountingView],
    [AdminNavLink.ApiKeys]: [Permission.AdminApiKeyView],
    [AdminNavLink.Notifications]: [Permission.AdminEventsView],
    [AdminNavLink.BetaFeatures]: [Permission.AdminBetaFeaturesView],
    [AdminNavLink.Jobs]: [Permission.AdminJobsView],
};
