import { AddonItem, AddonType, OrganizationStatus, PaymentSchema, PlatformTier } from '@hofy/api-shared';
import { DateTimeString, Decimal, Months, Percent, UUID } from '@hofy/global';

export interface OrganizationAddonConfigDto {
    id: number;
    name: string;
    type: AddonType;
    config: AddonConfigDto;
    validForOrganization: AddonConfigValidForOrgDto;
    isEligibleForEnrollmentByAdmins: boolean;
}

interface AddonConfigDto {
    id: number | null;
    discount: Percent;
    assetAgeMax: Months | null;
    enrollmentDuration: Months | null;
    enrollmentPaymentSchema: PaymentSchema | null;
    active: boolean;
}

export type AddonConfigValidForOrgDto =
    | {
          state: true;
      }
    | ({ state: false } & AddonConfigInvalidReasonDto);

export type AddonConfigInvalidReasonDto =
    | {
          invalidReason: AddonConfigInvalidReason.InvalidOrganizationStatus;
          organizationStatus: OrganizationStatus;
      }
    | {
          invalidReason: AddonConfigInvalidReason.InvalidTier;
          validTiers: PlatformTier[];
      }
    | {
          invalidReason: AddonConfigInvalidReason.InactiveAddon;
      };

export enum AddonConfigInvalidReason {
    InvalidTier = 'invalid_tier',
    InvalidOrganizationStatus = 'invalid_organization_status',
    InactiveAddon = 'inactive_addon',
}

export interface OrganizationAddonConfigDetailsDto {
    id: number | null;
    organizationId: UUID;
    addonName: string;
    discount: Percent;
    assetAgeMax: Months | null;
    enrollmentDuration: Months | null;
    enrollmentPaymentSchema: PaymentSchema | null;
    active: AddonConfigActiveDto;
    tokensByPool: Record<string, AvailableTokenCount> | null;
    consumedTokens: ConsumedTokenDto[];
}

export interface AvailableTokenCount {
    total: Decimal;
    used: Decimal;
    available: Decimal;
}

type AddonConfigActiveDto =
    | {
          state: true;
          isReadonly: boolean;
      }
    | {
          state: false;
      };

export interface ConsumedTokenDto {
    service: AddonItem;
    multiplier: Decimal;
    consumedAt: DateTimeString;
}
