import React, { FC, ReactNode, useMemo } from 'react';

import { useAdminsQuery } from '@hofy/api-admin';
import { UserRefDto } from '@hofy/api-shared';
import { FormDropdown } from '@hofy/common';
import { TestKeyAware } from '@hofy/ui';

interface AdminListDropdownProps extends TestKeyAware {
    value?: number;
    label: string;
    onChange(o: number | undefined): void;
    emptyContent?: ReactNode;

    isError?: boolean | string;
    isRequired?: boolean;
}

export const AdminListDropdown: FC<AdminListDropdownProps> = ({
    value,
    label,
    onChange,
    emptyContent = false,
    isError,
    isRequired,
    testKey,
}) => {
    const { data: admins } = useAdminsQuery();
    const toLabel = (v: UserRefDto) => `${v.firstName} ${v.lastName}`;
    const itemValue = useMemo(() => admins.find(u => u.idDeprecated === value), [admins, value]);
    return (
        <FormDropdown
            items={admins}
            labelFormatter={toLabel}
            onChange={i => onChange(i?.idDeprecated)}
            label={label}
            value={itemValue}
            toSearchLabel={toLabel}
            searchable
            emptyContent={emptyContent}
            isRequired={isRequired}
            isError={isError}
            testKey={testKey}
        />
    );
};
