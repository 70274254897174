interface AppConfig {
    env: string;
    isAdminApp?: boolean;
    sentryDsn?: string;
    segmentWriteKey?: string;
    hofyUrl?: string;
    zendeskUrl?: string;
    isNetsuite?: boolean;
    zendeskSupportDepartment?: string;
    zendeskScriptKey?: string;
    zendeskMessagingAuthId?: string;
    hotjarSiteId?: number;
    slackClientId?: string;
    hofyDocumentsUrl?: string;
    privacyPolicyUrl?: string;
    recaptchaSiteKey?: string;
    docsUrl?: string;
}

export const supportEmail = 'support@hofy.com';

export const baseUrl = `${window.location.protocol}//${window.location.host}`;

// @ts-ignore
export const APP_CONFIG: AppConfig = window.APP_CONFIG;
// @ts-ignore
export const GIT_HASH: string = global.GIT_HASH;

export const isE2E = () => {
    return APP_CONFIG.env === 'e2e';
};

export const isLocal = () => {
    return APP_CONFIG.env === 'local';
};

export const isDev = () => {
    return APP_CONFIG.env === 'dev' || APP_CONFIG.env === 'local';
};

export const isProd = () => {
    return APP_CONFIG.env === 'prod';
};

(window as any).GIT_HASH = GIT_HASH;
