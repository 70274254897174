import { ColorDye, getEnumValues } from '@hofy/global';

export enum ItemStatus {
    Active = 'active',
    BeingRepaired = 'being_repaired',
    SupplierReturn = 'supplier_return',
    Stolen = 'stolen',
    LostInTransit = 'lost_in_transit',
    WaitingForClearance = 'waiting_for_clearance',
    Cleared = 'cleared',
    Binned = 'binned',
    LeftWithUser = 'left_with_user',
    Shrinkage = 'shrinkage',
    DamagedByUser = 'damaged_by_user',
    DamagedInTransit = 'damaged_in_transit',
    DamagedAtWarehouse = 'damaged_at_warehouse',
    BrokenByUser = 'broken_by_user',
    BrokenInTransit = 'broken_in_transit',
    BrokenAtWarehouse = 'broken_at_warehouse',
    AddedByMistake = 'added_by_mistake',
    ReturnedToHofy = 'returned_to_hofy',
}

export const writtenOffStatus = [
    ItemStatus.SupplierReturn,
    ItemStatus.Stolen,
    ItemStatus.LostInTransit,
    ItemStatus.Cleared,
    ItemStatus.Binned,
    ItemStatus.LeftWithUser,
    ItemStatus.Shrinkage,
    ItemStatus.BrokenByUser,
    ItemStatus.BrokenInTransit,
    ItemStatus.BrokenAtWarehouse,
    ItemStatus.AddedByMistake,
];

const damagedStatuses = [
    ItemStatus.DamagedByUser,
    ItemStatus.DamagedInTransit,
    ItemStatus.DamagedAtWarehouse,
];

const brokenStatuses = [ItemStatus.BrokenByUser, ItemStatus.BrokenInTransit, ItemStatus.BrokenAtWarehouse];

const defectiveStatuses = [...damagedStatuses, ...brokenStatuses];

// item can be sent to a repair
export const validStatusesToRepair = [ItemStatus.Active, ...damagedStatuses];

// item can be received from repair
export const validStatusesFromRepair = [ItemStatus.Active, ...defectiveStatuses];

export const getValidItemStatusesWhenResolvingRepair = (
    isRepairResolvedSuccessfully: boolean,
): ItemStatus[] => {
    return isRepairResolvedSuccessfully ? [ItemStatus.Active] : brokenStatuses;
};

export const isDefectiveStatus = (s: ItemStatus) => defectiveStatuses.includes(s);
export const isValidRepairStatus = (s: ItemStatus) => validStatusesToRepair.includes(s);
export const isAssetArchived = (s: ItemStatus) => archivedAssetStatuses.includes(s);
export const isWriteOff = (s: ItemStatus) => writtenOffStatus.includes(s);

export const allItemStatuses = getEnumValues<ItemStatus>(ItemStatus);

export const itemStatusColor: Record<ItemStatus, ColorDye> = {
    [ItemStatus.Active]: 'green',
    [ItemStatus.BeingRepaired]: 'teal',
    [ItemStatus.SupplierReturn]: 'grey',
    [ItemStatus.Stolen]: 'red',
    [ItemStatus.LostInTransit]: 'red',
    [ItemStatus.WaitingForClearance]: 'grape',
    [ItemStatus.Cleared]: 'grape',
    [ItemStatus.Binned]: 'grey',
    [ItemStatus.LeftWithUser]: 'grey',
    [ItemStatus.Shrinkage]: 'grey',
    [ItemStatus.DamagedByUser]: 'red',
    [ItemStatus.DamagedInTransit]: 'red',
    [ItemStatus.DamagedAtWarehouse]: 'red',
    [ItemStatus.BrokenByUser]: 'red',
    [ItemStatus.BrokenInTransit]: 'red',
    [ItemStatus.BrokenAtWarehouse]: 'red',
    [ItemStatus.AddedByMistake]: 'grey',
    [ItemStatus.ReturnedToHofy]: 'blue',
};

export const archivedAssetStatuses = [
    ItemStatus.BrokenByUser,
    ItemStatus.BrokenAtWarehouse,
    ItemStatus.BrokenInTransit,
    ItemStatus.LostInTransit,
    ItemStatus.Stolen,
    ItemStatus.LeftWithUser,
    ItemStatus.Binned,
    ItemStatus.Cleared,
    ItemStatus.ReturnedToHofy,
];
