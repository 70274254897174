import { xor } from 'lodash';
import React, { FC } from 'react';

import { formatAdminName, unassignedUser, UserRefDto } from '@hofy/api-shared';
import { SeparatedContainer } from '@hofy/common';
import { FilterChip } from '@hofy/ui';

interface AdminFilterRendererProps {
    admins: UserRefDto[];
    adminIds: number[];
    onChange(admin: number[]): void;
}

export const AdminFilterRenderer: FC<AdminFilterRendererProps> = ({ adminIds, admins, onChange }) => {
    const removeAdmin = (adminId: number) => {
        onChange(xor(adminIds, [adminId]));
    };

    if (adminIds.includes(unassignedUser)) {
        return <FilterChip label='Unassigned' onClear={() => onChange([])} color='blue' />;
    }

    return (
        <SeparatedContainer row orientation='horizontal' spacing={10} marginLeft={16}>
            {admins.map(
                admin =>
                    adminIds.includes(admin.idDeprecated) && (
                        <FilterChip
                            label={formatAdminName(admin)}
                            onClear={() => removeAdmin(admin.idDeprecated)}
                            color='blue'
                            key={admin.id}
                        />
                    ),
            )}
        </SeparatedContainer>
    );
};
