import { useMutation, useQueryClient } from '@tanstack/react-query';

import { contractService, itemCacheKey } from '@hofy/api-admin';
import { PaymentSchema } from '@hofy/api-shared';
import { DateString, Months } from '@hofy/global';
import { FormErrors, isRequired, minLength, RequiredKeys, useForm, useToast, validator } from '@hofy/ui';

interface EnrollAssetForm {
    activeOn: DateString | null;
    addonIds: number[];
    duration: Months | null;
    paymentSchema: PaymentSchema | null;
}

export const useEnrollDevicesIntoServicePackage = (itemsIds: number[], onSuccess?: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: contractService.enrollItemsIntoServicePackage,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [itemCacheKey],
            });
            showToast({
                type: 'positive',
                message: 'Assets enrolled into service package successfully',
            });
            onSuccess?.();
        },
    });

    const form = useForm<EnrollAssetForm, RequiredKeys<EnrollAssetForm>, FormErrors<EnrollAssetForm>>({
        initial: {
            activeOn: null,
            addonIds: [],
            duration: null,
            paymentSchema: null,
        },
        onSubmit: ({ activeOn, addonIds, duration, paymentSchema }) =>
            mutation.mutate({
                activeOn: activeOn,
                itemIds: itemsIds,
                addonIds: addonIds,
                duration: duration,
                paymentSchema: paymentSchema,
            }),
        validate: validator<EnrollAssetForm>({
            activeOn: isRequired('Activation date is required'),
            addonIds: minLength('Please select at least 1 addon to enroll', 0),
            duration: isRequired('Duration is required'),
            paymentSchema: isRequired('Payment schema is required'),
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
