import { intersection, toPairs } from 'lodash';
import { createContext, useContext } from 'react';

import { allManagerRoles, Permission, Role } from '@hofy/api-shared';
import { asArray } from '@hofy/helpers';

import { emptySessionState } from './types/SessionState';

export const SessionContext = createContext(emptySessionState);

export const hasAdminPermission = (permissions: Permission[]): boolean => {
    return permissions.includes(Permission.AdminBasic);
};

export const isGoogleLogin = (provider: string): boolean => {
    return provider === 'google';
};

export const isPasswordLogin = (provider: string): boolean => {
    return provider === 'password';
};

export const isManager = (roles: Role[]): boolean => {
    return allManagerRoles.some(role => hasRole(roles, role));
};

const hasRole = (roles: Role[], r: Role): boolean => {
    return roles.includes(r);
};

export const roleRequiredForPermission = (
    roles: Partial<Record<Role, Permission[]>>,
    permission: Permission[],
    currentRoles: Role[],
): Role[] => {
    const hasCommonPermission = (p1: Permission[], p2: Permission[], currentRoles: Role[], r: Role) =>
        intersection(p1, p2).length > 0 && !currentRoles.includes(r);

    return toPairs(roles)
        .filter(v => hasCommonPermission(v[1], permission, currentRoles, v[0] as Role))
        .map(v => v[0] as Role);
};

const hasPermission = (permissions: Permission[], r: Permission): boolean => {
    return permissions.includes(r);
};

export const hasAnyPermission = (
    sessionPermissions: Permission[],
    requiredPermissions: Permission[],
): boolean => {
    return requiredPermissions.some(p => hasPermission(sessionPermissions, p));
};

export const useSession = () => {
    const { session, isInitialized, roles } = useContext(SessionContext);
    const permissions = session?.permissions || [];
    return {
        session: session!,
        isInitialized,
        isManager: isManager(roles),
        hasAdminPermission: hasAdminPermission(permissions),
        roles,
        hasRole: (r: Role) => hasRole(roles, r),
        hasPermission: (p: Permission) => hasPermission(permissions, p),
        hasAnyPermission: (requiredPermissions: Permission[]) =>
            hasAnyPermission(permissions, requiredPermissions),
        roleRequiredForPermission: (v: Permission | Permission[]) =>
            roleRequiredForPermission(session?.roleMapping || {}, asArray(v), roles || []),
    };
};

export const useManagerSession = () => {
    const { session, hasPermission } = useSession();
    return { ...session.managerProfile!, managerPiiAccess: hasPermission(Permission.UsersPiiView) };
};
