import {
    AddressDto,
    AuditableModelDeprecatedDto,
    HofySubsidiaryRef,
    NetsuiteStatusDto,
    PurchaseOrderStatus,
    Role,
    SupplierRefDto,
    UserRefDto,
} from '@hofy/api-shared';
import { AnyDateString, Currency, DateString, DateTimeString, Price, UUID } from '@hofy/global';

import { PurchaseOrderUserDto, PurchaseOrderWarehouseDto } from './PurchaseOrderDto';
import { PurchaseOrderItemDto } from './PurchaseOrderItemDto';

export interface PurchaseOrderDetailsDto extends AuditableModelDeprecatedDto {
    uuid: UUID;
    purchaseOrderReference: string;
    purchaseOrderSupplierReference: string | null;
    isDropship: boolean;
    status: PurchaseOrderStatus;
    totalPrice: Price;
    totalTaxValue: Price;
    handlingCost: Price;
    hofySubsidiary: HofySubsidiaryRef;
    hofySubsidiaryId: number;
    orderedOn: DateString;
    declinedAt: DateTimeString | null;
    cancelledAt: DateTimeString | null;
    approvedAt: DateTimeString | null;
    pendingReceiptOn: DateString | null;
    partiallyReceivedAt: DateTimeString | null;
    pendingBillingAt: DateTimeString | null;
    partiallyBilledAt: DateTimeString | null;
    partiallyBilledAndReceivedAt: DateTimeString | null;
    billedAt: DateTimeString | null;
    closedAt: DateTimeString | null;
    estimatedReceiveOn: DateString | null;
    supplier: SupplierRefDto;
    toUser: PurchaseOrderUserDto | null;
    toWarehouse: PurchaseOrderWarehouseDto | null;
    toAddress: AddressDto | null;
    fromAddress: AddressDto | null;
    purchaseOrderItems: PurchaseOrderItemDto[];
    purchaseOrderApprovals: PurchaseOrderApprovalDto[];
    purchaseOrderShipments: PurchaseOrderShipmentDto[];
    currency: Currency;
    purchaseOrderFiles: PurchaseOrderFileDto[];
    notes: string | null;
    netsuite: NetsuiteStatusDto;
}

export interface PurchaseOrderApprovalDto {
    id: number;
    uuid: UUID;
    parentApprovalId: number;
    requiredRole: Role[];
    approvedAt: DateTimeString | null;
    declinedAt: DateTimeString | null;
    approvedBy: UserRefDto | null;
    declinedBy: UserRefDto | null;
}

interface PurchaseOrderShipmentDto {
    id: number;
}

interface PurchaseOrderFileDto {
    id: number;
    filename: string;
    createdAt: DateTimeString;
}

export const getPurchaseOrderStatusDate = (
    purchaseOrder: PurchaseOrderDetailsDto,
    status: PurchaseOrderStatus,
): AnyDateString => {
    switch (status) {
        case PurchaseOrderStatus.PendingApproval:
            return purchaseOrder.orderedOn;
        case PurchaseOrderStatus.Declined:
            return purchaseOrder.declinedAt!;
        case PurchaseOrderStatus.Cancelled:
            return purchaseOrder.cancelledAt!;
        case PurchaseOrderStatus.Approved:
            return purchaseOrder.approvedAt!;
        case PurchaseOrderStatus.PendingReceipt:
            return purchaseOrder.pendingReceiptOn!;
        case PurchaseOrderStatus.PartiallyReceived:
            return purchaseOrder.partiallyReceivedAt!;
        case PurchaseOrderStatus.PendingBilling:
            return purchaseOrder.pendingBillingAt!;
        case PurchaseOrderStatus.PartiallyBilled:
            return purchaseOrder.partiallyBilledAt!;
        case PurchaseOrderStatus.PartiallyBilledAndReceived:
            return purchaseOrder.partiallyBilledAndReceivedAt!;
        case PurchaseOrderStatus.Billed:
            return purchaseOrder.billedAt!;
        case PurchaseOrderStatus.Closed:
            return purchaseOrder.closedAt!;
    }
};

export const purchaseOrderNeedsApproval = (status: PurchaseOrderStatus): boolean => {
    return status === PurchaseOrderStatus.PendingApproval;
};
