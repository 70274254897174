import { useMutation } from '@tanstack/react-query';

import { purchaseOrderService } from '@hofy/api-admin';

export const useDownloadPurchaseOrder = () => {
    const mutation = useMutation({
        mutationFn: purchaseOrderService.downloadPurchaseOrderFile,
    });
    const download = (fileId: number) => {
        mutation.mutate(fileId);
    };

    return {
        download,
        isDownloadPurchaseOrderLoading: mutation.isPending,
    };
};
