import { capitalize } from 'lodash';
import React, { FC, useEffect } from 'react';

import { EnrollItemValidationDetailsDto, OrganizationAddonConfigDto } from '@hofy/api-admin';
import { PaymentSchema } from '@hofy/api-shared';
import { DateString } from '@hofy/global';
import { Color } from '@hofy/theme';
import { BaseTable, Box, ErrorStatePlaceholder, Paragraph3, Paragraph4 } from '@hofy/ui';
import { ProductCell } from '@hofy/ui-domain';

import { useValidateEnrollDevicesIntoServicePackage } from '../../../store/contracts/useValidateEnrollDevicesIntoServicePackage';
import { IdentifierCell } from './components/IdentifierCell';

interface DeviceEnrollmentValidationTableProps {
    addonsConfigs: OrganizationAddonConfigDto[];
    itemIds: number[];
    activeOn: DateString;
    addonIds: number[];
    paymentSchema: PaymentSchema;
    duration: number;

    setValidItemIds(ids: number[]): void;
}

export const DeviceEnrollmentValidationTable: FC<DeviceEnrollmentValidationTableProps> = ({
    addonsConfigs,
    itemIds,
    activeOn,
    addonIds,
    paymentSchema,
    duration,

    setValidItemIds,
}) => {
    const { data, isLoading, isError } = useValidateEnrollDevicesIntoServicePackage(
        itemIds,
        activeOn,
        addonIds,
        paymentSchema,
        duration,
    );
    const enrollmentIsValid = (item: EnrollItemValidationDetailsDto) => {
        return !item.itemFailReason && !item.addonsValidation?.some(a => a.addonFailReason);
    };
    useEffect(() => {
        if (isLoading) {
            setValidItemIds([]);
            return;
        }
        setValidItemIds(data.filter(enrollmentIsValid).map(item => item.id));
    }, [data, isLoading]);

    if (isError || !data) {
        return <ErrorStatePlaceholder />;
    }

    return (
        <BaseTable
            isLoading={isLoading}
            data={data.sort(
                (a, b) => a.id * (enrollmentIsValid(a) ? -1 : 1) - b.id * (enrollmentIsValid(b) ? -1 : 1),
            )}
            toKey={item => item.uuid}
            columns={[
                {
                    id: 'id',
                    flexGrow: 1,
                    header: 'Identifier',
                    renderer: item => <IdentifierCell id={item.id} publicId={item.publicId} />,
                },
                {
                    id: 'product',
                    flexGrow: 3,
                    header: 'Product',
                    renderer: item => <ProductCell product={item.product} />,
                },
                {
                    id: 'validations',
                    flexGrow: 3,
                    header: 'Validations',
                    renderer: item => (
                        <DeviceEnrollmentValidationTableRow
                            addonsConfigs={addonsConfigs}
                            item={item}
                            isValid={enrollmentIsValid(item)}
                        />
                    ),
                },
            ]}
        />
    );
};

interface DeviceEnrollmentValidationTableRowProps {
    addonsConfigs: OrganizationAddonConfigDto[];
    item: EnrollItemValidationDetailsDto;
    isValid: boolean;
}

const DeviceEnrollmentValidationTableRow: FC<DeviceEnrollmentValidationTableRowProps> = ({
    addonsConfigs,
    item,
    isValid,
}) => {
    const parseError = (s: string) => {
        return capitalize(s.replace(/_/g, ' '));
    };
    const getAddonName = (id: number) => {
        return addonsConfigs.find(a => a.id === id)?.name ?? id.toString();
    };

    if (isValid) {
        return (
            <Box column gap={5}>
                <Paragraph3 color={Color.ContentPositive} bold>
                    Validation passed
                </Paragraph3>
                <Box column marginLeft={5}>
                    {item.addonsValidation?.map(addon => (
                        <Box row key={addon.addonId} gap={2}>
                            <Paragraph4 bold>{getAddonName(addon.addonId)}:</Paragraph4>
                            {addon.enrollmentDuration && (
                                <Paragraph4 color={Color.ContentTertiary}>
                                    {addon.enrollmentDuration} months
                                </Paragraph4>
                            )}
                        </Box>
                    ))}
                </Box>
            </Box>
        );
    }
    return (
        <Box column gap={5}>
            <Box row gap={5}>
                <Paragraph3 color={Color.ContentWarning} bold>
                    Validation failed
                </Paragraph3>
                <Paragraph4 color={Color.ContentTertiary} italic>
                    (device will be skipped)
                </Paragraph4>
            </Box>

            <Box column marginLeft={5}>
                <Box row gap={2}>
                    <Paragraph4 bold>Item: </Paragraph4>
                    {item.itemFailReason && (
                        <Paragraph4 color={Color.ContentTertiary}>
                            {parseError(item.itemFailReason)}
                        </Paragraph4>
                    )}
                    {!item.itemFailReason && (
                        <Paragraph4 color={Color.ContentTertiary} italic>
                            Valid
                        </Paragraph4>
                    )}
                </Box>

                {item.addonsValidation?.map(addon => (
                    <Box row key={addon.addonId} gap={2}>
                        <Paragraph4 bold>{getAddonName(addon.addonId)}:</Paragraph4>
                        {addon.addonFailReason && (
                            <Paragraph4 color={Color.ContentTertiary} italic>
                                {parseError(addon.addonFailReason)}
                            </Paragraph4>
                        )}
                        {!addon.addonFailReason && (
                            <Paragraph4 italic color={Color.ContentTertiary}>
                                Valid
                            </Paragraph4>
                        )}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};
