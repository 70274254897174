export const itemErrorI18n = {
    'api-error.item-not-assigned-to-warehouse.title': 'Item is not assigned to warehouse',
    'api-error.item-not-assigned-to-warehouse.message': 'Cannot perform this operation',
    'api-error.creating-item-at-warehouse.title': 'Error creating item received at warehouse',
    'api-error.creating-item-at-warehouse.message': 'Cannot perform this operation',
    'api-error.item-has-no-country.title': 'Item has no country',
    'api-error.item-has-no-country.message': 'Cannot perform this operation',
    'api-error.item-is-not-organization-owned.title': 'Item does not belong to organization',
    'api-error.item-is-not-organization-owned.message': 'Cannot perform this operation',
    'api-error.item-belongs-to-another-organization.title': 'Item belongs to another organization',
    'api-error.item-belongs-to-another-organization.message': 'Cannot perform this operation',
    'api-error.item-is-not-active.title': 'Item is not active',
    'api-error.item-is-not-active.message': 'Cannot perform this operation',
    'api-error.item-is-not-with-user.title': 'Item is not with user',
    'api-error.item-is-not-with-user.message': 'Cannot perform this operation',
    'api-error.item-is-consumable.title': 'Item is consumable',
    'api-error.item-is-consumable.message': 'Cannot perform this operation',
    'api-error.invalid-item-status-for-repair.title': 'Item has invalid status for being repaired',
    'api-error.invalid-item-status-for-repair.message': 'Cannot perform this operation',
    'api-error.damaged-item-with-unresolved-repairs.title': 'Damaged item with unresolved repairs',
    'api-error.damaged-item-with-unresolved-repairs.message': 'Cannot perform this operation',
    'api-error.invalid-status-for-location.title': 'Invalid status for location',
    'api-error.invalid-status-for-location.message': 'Cannot perform this operation',
    'api-error.item-cant-be-used-for-loaner-or-store-and-reuse.title':
        'Item cant be used for loaner or store and reuse',
    'api-error.item-cant-be-used-for-loaner-or-store-and-reuse.message': 'Cannot perform this operation',
    'api-error.no-warehouse-assigned.title': 'No warehouse assigned for item',
    'api-error.no-warehouse-assigned.message': 'Cannot perform this operation',
    'api-error.no-warehouse-assigned-for-shipment.title': 'No warehouse assigned for item shipment',
    'api-error.no-warehouse-assigned-for-shipment.message': 'Cannot perform this operation',
    'api-error.no-user-assigned.title': 'Cannot update item without user assigned',
    'api-error.no-user-assigned.message': 'Cannot perform this operation',
    'api-error.bin-required-for-core-warehouse.title': 'Bin required for core warehouse',
    'api-error.bin-required-for-core-warehouse.message': 'Cannot perform this operation',
    'api-error.warehouse-not-found.title': 'Warehouse not found',
    'api-error.warehouse-not-found.message': 'Cannot perform this operation',
    'api-error.item-price-zero.title': 'Item price must not be 0',
    'api-error.item-price-zero.message': 'Cannot perform this operation',
    'api-error.creating-item-code.title': 'Error creating item code',
    'api-error.creating-item-code.message': 'Cannot perform this operation',
    'api-error.existing-code.title': 'Item code already exists',
    'api-error.existing-code.message': 'Cannot perform this operation',
    'api-error.get-fixed-assets.title': 'Error retrieving fixed assets for item',
    'api-error.get-fixed-assets.message': 'Cannot perform this operation',
    'api-error.no-active-fixed-asset.title': 'There are no active fixed assets',
    'api-error.no-active-fixed-asset.message': 'Cannot perform this operation',
    'api-error.invalid-report-header.title': 'Invalid report header',
    'api-error.invalid-report-header.message': 'Report header is invalid.',
    'api-error.invalid-report-qty.title': 'Invalid report qty',
    'api-error.invalid-report-qty.message': 'Row quantity is invalid.',
    'api-error.address-required-for-collection.title': 'Address required for collection',
    'api-error.address-required-for-collection.message': 'Cannot perform this operation',
    'api-error.different-user-and-contract-region.title': 'Different user and contract region',
    'api-error.different-user-and-contract-region.message': 'Cannot perform this operation',
    'api-error.invalid-assigned-user.title': 'Invalid assigned user',
    'api-error.invalid-assigned-user.message': 'Cannot perform this operation',
    'api-error.missing-asset-assigned-user.title': 'Missing asset assigned user',
    'api-error.missing-asset-assigned-user.message': 'Cannot perform this operation',
    'api-error.suspended-organization.title': 'Organization is suspended',
    'api-error.suspended-organization.message': 'Cannot perform this operation',
    'api-error.trial-organization.title': 'Organization status is trial',
    'api-error.trial-organization.message': 'Cannot perform this operation',
    'api-error.invalid-store-action-invalid-location.title': 'Invalid storage location for store action',
    'api-error.invalid-store-action-invalid-location.message': 'Cannot perform this operation',
};
