import { omit, xor } from 'lodash';

import { AdminAssignmentFilters } from '@hofy/api-admin';
import {
    CollectionSurveyStatus,
    OrderType,
    Ownership,
    ShipmentStatus,
    ShipmentType,
    unassignedUser,
} from '@hofy/api-shared';
import { AnyRegion, Country, DateString } from '@hofy/global';
import { countObjectValues } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import {
    useArrayQueryParam,
    useBooleanQueryParam,
    useDateRangeQueryParam,
    useStringQueryParam,
} from '@hofy/router';

export const useAdminAssignmentsFilters = () => {
    const [search, setSearch] = useStringQueryParam('search', '');
    const [isPending, setIsPending] = useBooleanQueryParam('isPending');
    const [shipmentStatus, setShipmentStatus] = useArrayQueryParam<ShipmentStatus>('shipmentStatus');
    const [assignedUsers, setAssignedUsers] = useArrayQueryParam<number>('assignedUser');
    const [containingLaptopOnly, setContainingLaptopOnly] = useBooleanQueryParam('containingLaptopOnly');
    const [configRequired, setConfigRequired] = useBooleanQueryParam('configRequired');
    const [futureScheduled, setFutureScheduled] = useBooleanQueryParam('futureScheduled');
    const [scheduledOnly, setScheduledOnly] = useBooleanQueryParam('scheduledOnly');
    const [allShipments, setAllShipments] = useBooleanQueryParam('allShipments');
    const [newJoiners, setNewJoiners] = useBooleanQueryParam('newJoiners');
    const [isLoaner, setIsLoaner] = useBooleanQueryParam('isLoaner');
    const [isStoreAndReuse, setIsStoreAndReuse] = useBooleanQueryParam('isStoreAndReuse');
    const [isDisposal, setIsDisposal] = useBooleanQueryParam('isDisposal');
    const [isPartner, setPartner] = useBooleanQueryParam('isPartner');
    const [scanned, setScanned] = useBooleanQueryParam('scanned');
    const [unscanned, setUnscanned] = useBooleanQueryParam('unscanned');
    const [ownership, setOwnership] = useStringQueryParam<Ownership>('ownership');
    const [region, setRegion] = useArrayQueryParam<AnyRegion>('region');
    const [countries, setCountries] = useArrayQueryParam<Country>('country');
    const [warehouses, setWarehouses] = useArrayQueryParam<number>('warehouse');
    const [orderType, setOrderType] = useStringQueryParam<OrderType>('orderType');
    const [shipmentType, setShipmentType] = useStringQueryParam<ShipmentType>('shipmentType');
    const [collectionSurveyStatus, setCollectionSurveyStatus] =
        useArrayQueryParam<CollectionSurveyStatus>('collectionSurveyStatus');
    const [otdDate, setOtdDate] = useStringQueryParam<DateString>('otdDate');
    const [shipDateRange, setShipDateRange] = useDateRangeQueryParam('shipDateRange');
    const [deliveryDate, setDeliveryDate] = useStringQueryParam<DateString>('deliveryDate');
    const [isOnHold, setIsOnHold] = useBooleanQueryParam('isOnHold');

    const setUnassigned = () => {
        setAssignedUsers(xor(assignedUsers, [unassignedUser]));
    };

    const filters = useStructMemo<AdminAssignmentFilters>({
        search,
        isPending,
        shipmentStatus,
        shipmentType,
        ownership,
        containingLaptopOnly,
        allShipments,
        configRequired,
        assignedUsers,
        newJoiners,
        futureScheduled,
        scheduledOnly,
        isLoaner,
        isStoreAndReuse: isStoreAndReuse,
        isDisposal,
        isPartner,
        scanned,
        unscanned,
        region,
        countries,
        warehouses,
        orderType,
        collectionSurveyStatus,
        otdDate,
        shipDateRange,
        deliveryDate,
        isOnHold,
    });

    const filtersToCount = omit(filters, 'search', 'futureScheduled', 'isPending');
    const filterCount = countObjectValues(filtersToCount);

    return {
        filters,
        filterCount,
        setSearch,
        setAllShipments,
        setIsPending,
        setShipmentStatus,
        setContainingLaptopOnly,
        setConfigRequired,
        setAssignedUsers,
        setUnassigned,
        setNewJoiners,
        setShipmentType,
        setOwnership,
        setFutureScheduled,
        setScheduledOnly,
        setRegion,
        setCountries,
        setWarehouses,
        setOrderType,
        setIsLoaner,
        setIsStoreAndReuse,
        setIsDisposal,
        setPartner,
        setScanned,
        setUnscanned,
        setCollectionSurveyStatus,
        setOtdDate,
        setShipDateRange,
        setDeliveryDate,
        setIsOnHold,
    };
};
