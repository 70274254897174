import React, { FC } from 'react';

import { formatOptionalUserName, UserRefDto } from '@hofy/api-shared';

import { UserDetailsLink } from '../../usersPage/UserDetailsLink';

interface ConfiguredByProps {
    user: UserRefDto | null;
}

export const UserDetails: FC<ConfiguredByProps> = ({ user }) => {
    const userName = formatOptionalUserName(user);

    if (!user) {
        return <>{userName}</>;
    }

    return <UserDetailsLink id={user.idDeprecated}>{userName}</UserDetailsLink>;
};
