import { Role, UserRefDto, UserWithCountryRefDto } from '@hofy/api-shared';
import { PageRequest, PageResponse, stringifyUrl, UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { AdminUserFilters } from './types/AdminUserFilters';
import { CreateUserPayload, UpdateUserPayload } from './types/UpdateUserPayload';
import { UserDto } from './types/UserDto';
import { UploadUsersPayload } from './types/UsersUploadPayload';
import { UsersUploadResponse } from './types/UsersUploadResponse';

interface UsersRefResponse {
    users: UserRefDto[];
}

class UserService {
    public getAdmins = async (role = Role.FulfillmentAdmin, getOffBoarded = false): Promise<UserRefDto[]> => {
        const response = await restClient.getJson<UsersRefResponse>(
            stringifyUrl({
                url: `/api/admin/users/admins`,
                query: {
                    role,
                    getOffBoarded,
                },
            }),
        );
        return response.users;
    };

    public getUser = async (userId: number): Promise<UserDto> => {
        return await restClient.getJson<UserDto>(`/api/admin/users/${userId}`);
    };

    public listUsers = (filters: AdminUserFilters, page: PageRequest): Promise<PageResponse<UserDto>> => {
        return restClient.getJson<PageResponse<UserDto>>(
            stringifyUrl({
                url: `/api/admin/users`,
                query: {
                    ...filters,
                    page: page.page,
                    pageSize: page.pageSize,
                },
            }),
        );
    };

    public uploadUsers = async (payload: UploadUsersPayload): Promise<UsersUploadResponse> => {
        return restClient.postJson(`/api/admin/users/upload`, payload);
    };

    public getUserRefs = (organizationId?: UUID): Promise<UserWithCountryRefDto[]> => {
        return restClient.getJson<UserWithCountryRefDto[]>(
            stringifyUrl({
                url: `/api/admin/users/refs`,
                query: {
                    organizationId,
                },
            }),
        );
    };
    public createUser = async (
        payload: CreateUserPayload,
    ): Promise<{
        id: number;
    }> => {
        return await restClient.postJson('/api/admin/users', payload);
    };

    public updateUser = async (userId: number, payload: UpdateUserPayload): Promise<void> => {
        return await restClient.post(`/api/admin/users/${userId}`, payload);
    };

    public deleteUser = async (id: number): Promise<void> => {
        return await restClient.delete(`/api/admin/users/${id}`).then();
    };

    public syncToZendesk = async (userId: number) =>
        await restClient.post(
            stringifyUrl({
                url: `/api/admin/users/sync-zendesk`,
                query: {
                    userId,
                },
            }),
        );
}

export const userService = new UserService();
