import { capitalize, xor } from 'lodash';
import React, { FC } from 'react';

import { AdminAssignmentFilters } from '@hofy/api-admin';
import {
    CollectionSurveyStatus,
    collectionSurveyStatusColors,
    OrderType,
    orderTypeColors,
    Ownership,
    ShipmentStatus,
    shipmentStatusColors,
    ShipmentType,
    shipmentTypeColors,
    UserRefDto,
    useTrOwnership,
    useTrShipmentStatus,
    useTrShipmentType,
} from '@hofy/api-shared';
import { AnyRegion, Country, DateRangeStrings } from '@hofy/global';
import { formatDate, formatDateRange } from '@hofy/helpers';
import { useTrCountry, useTrRegion } from '@hofy/i18n';
import { FilterChip } from '@hofy/ui';

import { useTrOrderType } from '../../../../store/assignments/useTrOrderType';
import { useTrCollectionSurveyStatus } from '../../../../store/collectionRequests/useTrCollectionSurveyStatus';
import { AssignedUsersFilterChips } from './AssignedUsersFilterChips';

interface OrdersPageActiveFilterChipsProps {
    filters: AdminAssignmentFilters;
    allUsers: UserRefDto[];
    onAllShipmentsChange(v: boolean | null): void;
    onAssignedUserChange(value: number[]): void;
    onCountriesChange(countries: Country[]): void;
    onContainingLaptopOnlyChange(v: boolean | null): void;
    onConfigRequiredChange(v: boolean | null): void;
    onShipmentStatusChange(status: ShipmentStatus[]): void;
    onNewJoinersChange(v: boolean | null): void;
    onIsLoanerChange(v: boolean | null): void;
    onIsStoreAndReuseChange(v: boolean | null): void;
    onScheduledOnlyChange(v: boolean | null): void;
    onIsDisposalChange(v: boolean | null): void;
    onRegionChange(v: AnyRegion[]): void;
    onOrderTypeChange(v: OrderType | null): void;
    onShipmentTypeChange(v: ShipmentType | null): void;
    onPartnerChange(v: boolean | null): void;
    onScannedChange(v: boolean | null): void;
    onUnscannedChange(v: boolean | null): void;
    onCollectionSurveyStatusChange(status: CollectionSurveyStatus[]): void;
    onOtdChange(date: string | null): void;
    onShipRangeChange(date: DateRangeStrings | null): void;
    onDeliveryChange(date: string | null): void;
    onOwnershipChange(v: Ownership | null): void;
}

export const OrdersPageActiveFilterChips: FC<OrdersPageActiveFilterChipsProps> = ({
    filters,
    allUsers,
    onAllShipmentsChange,
    onAssignedUserChange,
    onCountriesChange,
    onContainingLaptopOnlyChange,
    onConfigRequiredChange,
    onShipmentStatusChange,
    onNewJoinersChange,
    onRegionChange,
    onIsLoanerChange,
    onIsStoreAndReuseChange,
    onScheduledOnlyChange,
    onIsDisposalChange,
    onShipmentTypeChange,
    onOrderTypeChange,
    onPartnerChange,
    onScannedChange,
    onUnscannedChange,
    onCollectionSurveyStatusChange,
    onOtdChange,
    onShipRangeChange,
    onDeliveryChange,
    onOwnershipChange,
}) => {
    const trShipmentStatus = useTrShipmentStatus();
    const trOwnership = useTrOwnership();
    const trType = useTrOrderType();
    const trRegion = useTrRegion();
    const trCountry = useTrCountry();
    const trShipmentType = useTrShipmentType();
    const trCollectionSurveyStatus = useTrCollectionSurveyStatus();

    const removeShipmentStatus = (status: ShipmentStatus) => {
        onShipmentStatusChange(xor(filters.shipmentStatus, [status]));
    };

    const removeCollectionSurveyStatus = (status: CollectionSurveyStatus) => {
        onCollectionSurveyStatusChange(xor(filters.collectionSurveyStatus, [status]));
    };

    const removeRegion = (region: AnyRegion) => {
        onRegionChange(xor(filters.region, [region]));
    };

    const removeCountry = (country: Country) => {
        onCountriesChange(xor(filters.countries, [country]));
    };

    return (
        <>
            <AssignedUsersFilterChips
                allUsers={allUsers}
                selectedUserIds={filters.assignedUsers}
                onChange={onAssignedUserChange}
            />
            {filters.containingLaptopOnly === true && (
                <FilterChip
                    label='Containing laptop'
                    onClear={() => onContainingLaptopOnlyChange(null)}
                    color='purple'
                />
            )}
            {filters.configRequired === true && (
                <FilterChip
                    label='Config required'
                    onClear={() => onConfigRequiredChange(null)}
                    color='orange'
                />
            )}
            {filters.newJoiners === true && (
                <FilterChip label='New joiners' onClear={() => onNewJoinersChange(null)} color='blue' />
            )}
            {filters.shipmentStatus.map(status => (
                <FilterChip
                    key={status}
                    label={trShipmentStatus(status)}
                    onClear={() => removeShipmentStatus(status)}
                    color={shipmentStatusColors[status]}
                />
            ))}
            {filters.collectionSurveyStatus.map(status => (
                <FilterChip
                    key={status}
                    label={capitalize(`Survey: ${trCollectionSurveyStatus(status)}`)}
                    onClear={() => removeCollectionSurveyStatus(status)}
                    color={collectionSurveyStatusColors[status]}
                />
            ))}
            {filters.region.map(r => (
                <FilterChip key={r} label={trRegion(r)} onClear={() => removeRegion(r)} color='blue' />
            ))}
            {filters.countries.map(country => (
                <FilterChip
                    key={country}
                    label={trCountry(country)}
                    onClear={() => removeCountry(country)}
                    color='blue'
                />
            ))}
            {filters.orderType && (
                <FilterChip
                    label={trType(filters.orderType)}
                    onClear={() => onOrderTypeChange(null)}
                    color={orderTypeColors[filters.orderType]}
                />
            )}
            {filters.isLoaner && (
                <FilterChip label='Loaner' onClear={() => onIsLoanerChange(null)} color='orange' />
            )}
            {filters.isStoreAndReuse && (
                <FilterChip
                    label='Store and reuse'
                    onClear={() => onIsStoreAndReuseChange(null)}
                    color='orange'
                />
            )}
            {filters.isDisposal && (
                <FilterChip label='Only Disposals' onClear={() => onIsDisposalChange(null)} color='orange' />
            )}
            {filters.scheduledOnly && (
                <FilterChip label='Scheduled only' onClear={() => onScheduledOnlyChange(null)} color='teal' />
            )}
            {filters.shipmentType && (
                <FilterChip
                    label={trShipmentType(filters.shipmentType)}
                    onClear={() => onShipmentTypeChange(null)}
                    color={shipmentTypeColors[filters.shipmentType]}
                />
            )}
            {filters.isPartner && (
                <FilterChip label='Partner' onClear={() => onPartnerChange(null)} color='teal' />
            )}
            {filters.scanned && (
                <FilterChip label='Scanned' onClear={() => onScannedChange(null)} color='teal' />
            )}
            {filters.unscanned && (
                <FilterChip label='Unscanned' onClear={() => onUnscannedChange(null)} color='teal' />
            )}
            {filters.ownership && (
                <FilterChip
                    label={trOwnership(filters.ownership)}
                    onClear={() => onOwnershipChange(null)}
                    color='teal'
                />
            )}
            {filters.otdDate && (
                <FilterChip
                    label={'OTD before: ' + formatDate(filters.otdDate)}
                    onClear={() => onOtdChange(null)}
                    color='blue'
                />
            )}
            {filters.shipDateRange && (
                <FilterChip
                    label={'Ship by: ' + formatDateRange(filters.shipDateRange)}
                    onClear={() => onShipRangeChange(null)}
                    color='blue'
                />
            )}
            {filters.deliveryDate && (
                <FilterChip
                    label={'Est. Delivery before: ' + formatDate(filters.deliveryDate)}
                    onClear={() => onDeliveryChange(null)}
                    color='blue'
                />
            )}
            {filters.allShipments && (
                <FilterChip label='All shipments' onClear={() => onAllShipmentsChange(null)} color='orange' />
            )}
        </>
    );
};
